import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { ICollectionState } from './types';
import { IRootState } from '../types';

export const state: ICollectionState = {
  filmItems: [],
  miscItems: [],
  byProject: {},
  rewardDistrubutions: [],
  popcornBuckets: [],
  userTotalRewards: 0,
  userLastDayRewards: 0,
  unopenedBoxes: {}, // todo: once we use other unopened box getter
};

const namespaced = true;

const games: Module<ICollectionState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default games;
