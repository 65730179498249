import { render, staticRenderFns } from "./WalletAddressInput.vue?vue&type=template&id=3da7b351&"
import script from "./WalletAddressInput.vue?vue&type=script&lang=ts&"
export * from "./WalletAddressInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports