import { GetterTree } from 'vuex';
import { IProjectState, IProjectSeriesState } from './types';
import { IRootState } from '../types';
import { IProject, IProjectTimeline } from '~/types/project';
import { EpisodeAction } from '~/types/series';

export const getters: GetterTree<IProjectState, IRootState> = {
  getProjectBySlug:
    (state) =>
    (projectSlug: string): IProject | undefined => {
      return state.projects[projectSlug];
    },
  getProjectTimeline: (state) => (projectSlug: string) => {
    const timeline: IProjectTimeline | undefined =
      state.projectTimeline[projectSlug];
    return timeline;
  },
  getProjectEpisodeWatchProgress: (state) => (projectSlug: string) => {
    return state.projectSeriesState[projectSlug]?.episodeWatchProgress ?? {};
  },
  getProjectEpisodeWatchSuggestion: (state) => (projectSlug: string) => {
    return (
      state.projectSeriesState[projectSlug]?.episodeWatchSuggestion ?? {
        episodeNumber: 1,
        episodeAction: EpisodeAction.START,
      }
    );
  },
  getProjectSeriesState:
    (state) =>
    (projectSlug: string): IProjectSeriesState | undefined => {
      return state.projectSeriesState[projectSlug];
    },
};
