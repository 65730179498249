
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State, Mutation } from 'vuex-class';
import LoginAndRegister from '~/components/Authentication/LoginAndRegister.vue';
import DiscordConnect from '~/components/Discord/DiscordConnect.vue';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import ProfileNavigationDrawer from '~/components/Home/ProfileNavigationDrawer.vue';
import ProfileProgressIcon from '~/components/Home/ProfileProgressIcon.vue';
import TriggerWalletCreationBtn from '~/components/NewWallet/TriggerWalletCreationBtn.vue';
import NotificationBell from '~/components/SvgIcons/NotificationBell.vue';
import { IUser } from '~/store/profile/types';
import { ICurrentPage, IPreviousPage, TSiteConfigState } from '~/store/types';
import { INodesState, IUserNode } from '~/store/nodes/types';
import { IWallet } from '~/store/inventory/types';
import ReferAFriend from '~/components/Share/ReferralDialog.vue';
import LogoGalaFilm from '~/components/UIComponents/LogoGalaFilm.vue';
import LogoPoweredByGala from '~/components/UIComponents/LogoPoweredByGala.vue';
import UaTopToolbarLogo from '~/components/SignUp/UaTopToolbarLogo.vue';
import PromoMenu from './PromoMenu.vue';
import ProfileControls from './ProfileControls.vue';
import WalletConnectionErrorBanner from '~/components/Home/WalletConnectionErrorBanner.vue';
import ToolbarMarketingBanner from '~/components/Home/ToolbarMarketingBanner.vue';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';

interface ITab {
  icon?: string;
  name: string;
  title: string;
  to: string;
  requirement: boolean;
  includeDot?: boolean;
}

@Component({
  components: {
    ProfileNavigationDrawer,
    ProfileProgressIcon,
    FullScreenDialog,
    LoginAndRegister,
    NotificationBell,
    DiscordConnect,
    TriggerWalletCreationBtn,
    ReferAFriend,
    LogoGalaFilm,
    LogoPoweredByGala,
    ProfileControls,
    PromoMenu,
    UaTopToolbarLogo,
    WalletConnectionErrorBanner,
    ToolbarMarketingBanner,
  },
})
export default class TopToolbar extends Vue {
  @Prop(Array) readonly tabs!: Array<ITab>;
  @Prop({ type: Boolean, default: true }) readonly linkLogo!: boolean;
  @Prop(Boolean) readonly showWalletPrompt!: boolean;
  @Prop(Boolean) readonly showTopNavbarLinks!: boolean;
  @Prop(Boolean) readonly marketingMode!: boolean;
  @Prop(Number) readonly systemBarHeight!: number;
  @Prop(Number) readonly toolbarHeight!: number;
  @Prop(Boolean) readonly showUaLogo!: boolean;

  @State('currentPage') currentPage!: ICurrentPage;
  @State('previousPage') previousPage!: IPreviousPage;
  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State((notifications) => notifications.newNotifications.total, {
    namespace: 'notifications',
  })
  readonly newNotificationCount!: number;
  @State((nodes: INodesState) => nodes.userNodes, { namespace: 'nodes' })
  userNodes!: IUserNode[];
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State((profile) => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State((inventory) => inventory.isFetchingWallets, { namespace: 'inventory' })
  isFetchingWallets!: boolean;
  @State('wallets', { namespace: 'inventory' })
  private wallets!: IWallet[];

  @Mutation toggleReferralDialog!: (args: any) => void;

  @Action('getNewNotifications', { namespace: 'notifications' })
  private getNewNotifications!: () => void;

  @Getter('siteConfig')
  readonly siteConfigState!: TSiteConfigState;
  @Getter('showWalletConnectionErrorBanner', { namespace: 'web3Wallet' })
  readonly showWalletConnectionErrorBanner!: boolean;

  @State('hasClosedToolbarMarketingBanner')
  hasClosedToolbarMarketingBanner!: boolean;

  drawerControl = false;
  clipped = true;
  showCongratsModal = false;

  aboutItems = [
    {
      title: 'About Gala Film',
      to: '/about',
    },
    {
      title: '$FILM',
      to: '/film-token',
    },
    {
      title: 'Pitches',
      to: '/pitches',
    },
  ];

  get ethWalletAddress() {
    const ethWallet = this.wallets.find((wallet) => wallet.symbol === 'ETH');

    if (ethWallet && ethWallet.receiveAddress) {
      return ethWallet.receiveAddress;
    }

    return null;
  }

  get gyriWalletAddress() {
    const gyriWallet = this.wallets.find(
      (wallet) => wallet.symbol === 'GALA[GYRI]',
    );

    if (gyriWallet && gyriWallet.receiveAddress) {
      return gyriWallet.receiveAddress;
    }

    return null;
  }

  get showQuickBalance() {
    return (
      this.loggedIn &&
      !this.isFetchingWallets &&
      (this.walletExists || this.currentUser?.walletConnected)
    );
  }

  get galaCompanyLink() {
    return process.env.galaCompanyLink;
  }

  get primaryHomePageBannerRef() {
    return this.siteConfigState?.data?.homePageBanners?.[0]?._ref;
  }

  get homePageMarketingBanner() {
    if (!this.primaryHomePageBannerRef) {
      return;
    }

    return this.$sanityLookup.getDocumentFromId<
      ISanityDocuments[SanityDocumentSchema.MARKETING_BANNER]
    >({
      _id: this.primaryHomePageBannerRef,
      _type: SanityDocumentSchema.MARKETING_BANNER,
    })?.data;
  }

  get showToolbarMarketingBanner() {
    return (
      !this.hasClosedToolbarMarketingBanner && this.homePageMarketingBanner
    );
  }

  closeNavDrawer() {
    this.drawerControl = false;
  }

  handleNavDrawerClosed() {
    if (this.$refs.navDrawerActivator) {
      (this.$refs.navDrawerActivator as HTMLButtonElement).focus();
    }
  }

  goBack() {
    if (
      (this.currentPage.forceBackRoute || this.previousPage.sameAsCurrent) &&
      this.currentPage.backRoute
    ) {
      this.$router.push(this.currentPage.backRoute);
    } else {
      this.$router.back();
    }
  }

  @Watch('currentUser.loggedIn', { immediate: true })
  async onUserUpdated(loggedIn: Boolean) {
    if (loggedIn) {
      this.getNewNotifications();
    }
  }

  @Watch('showTopNavbarLinks')
  closeNavDrawerOnLinkChange(val: boolean) {
    this.closeNavDrawer();
  }

  @Watch('primaryHomePageBannerRef', { immediate: true })
  handlePrimaryHomePageBannerRefChange(
    current: string | undefined,
    previous: string | undefined,
  ) {
    if (current && current !== previous) {
      this.$sanityLookup.fetchDocument({
        _id: current,
        _type: SanityDocumentSchema.MARKETING_BANNER,
        fetchReferences: true,
      });
    }
  }
}
