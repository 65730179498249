import { ActionTree } from 'vuex';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import {
  ICollectionState,
  IGetPostersPayload,
  IGetPrizesPayload,
  IGetMysteryBoxesPayload,
  IGetMomentPacksPayload,
  IGetSceneStatsPayload,
  IGetScenesPayload,
  IGetCollectiblesPayload,
  IGetProjectSceneMomentsRewardDetailsPayload,
  IGetSceneAPIsPayload,
} from './types';
import { IRootState } from '../types';
import {
  IMysteryBox,
  IPoster,
  IPrize,
  IShard,
  IScene,
  IPack,
  ICollectible,
  IMoment,
} from '~/types/collections';
import {
  IFilmCollectionOverview,
  IFilmItemCollection,
  IFilmMomentStatsOverview,
  IFilmSceneStatsOverview,
  ISceneStats,
  IUserPopcornBuckets,
  IUserRewardsDistribution,
} from '~/types/my-collection';
import { parseRestrictionResponseForUnopenedMyseryBoxes } from '~/utils/collections';
import isQualified from '~/queries/isQualified.gql';
import {
  IHydratedSet,
  ISet,
  ERestrictionType,
} from '~/store/customReveal/types';

export const actions: ActionTree<ICollectionState, IRootState> = {
  async updateProjectStats(
    { commit, dispatch, state },
    payload: {
      projectStats: IFilmCollectionOverview;
      projectSlug: string;
    },
  ) {
    commit('updateProjectStats', {
      projectStats: payload.projectStats,
      projectSlug: payload.projectSlug,
    });
  },

  async getPosters({ commit, dispatch, state }, { slug }: IGetPostersPayload) {
    const posters: IPoster[] = await this.$filmApiService.getPosters(slug);
    await dispatch('updatePosters', { posters, projectSlug: slug });
  },

  async updatePosters(
    { commit, dispatch, state },
    payload: { posters: IPoster[]; projectSlug: string },
  ) {
    commit('updatePosters', {
      posters: payload.posters,
      projectSlug: payload.projectSlug,
    });
  },

  async getPrizes({ commit, dispatch, state }, { slug }: IGetPrizesPayload) {
    const prizes: IPrize[] = await this.$filmApiService.getPrizes(slug);
    await dispatch('updatePrizes', { prizes, projectSlug: slug });
  },

  async updatePrizes(
    { commit, dispatch, state },
    payload: { prizes: IPrize[]; projectSlug: string },
  ) {
    commit('updatePrizes', {
      prizes: payload.prizes,
      projectSlug: payload.projectSlug,
    });
  },

  async getMysteryBoxes(
    { commit, dispatch, state },
    { slug }: IGetMysteryBoxesPayload,
  ) {
    const mysteryBoxes: IMysteryBox[] =
      (await this.$filmApiService.getMysteryBoxes(slug)) || [];
    await dispatch('updateMysteryBoxes', { mysteryBoxes, projectSlug: slug });
  },

  async updateMysteryBoxes(
    { commit, dispatch, state },
    payload: { mysteryBoxes: IMysteryBox[]; projectSlug: string },
  ) {
    commit('updateMysteryBoxes', {
      mysteryBoxes: payload.mysteryBoxes,
      projectSlug: payload.projectSlug,
    });
  },

  async getScenes(
    { commit, dispatch, state },
    { projectSlug }: IGetScenesPayload,
  ) {
    const scenes: IScene[] =
      (await this.$filmApiService.getScenes(projectSlug)) || [];
    await dispatch('updateScenes', { scenes, projectSlug });
  },

  async updateScenes(
    { commit, dispatch, state },
    payload: { scenes: IScene[]; projectSlug: string },
  ) {
    commit('updateScenes', {
      scenes: payload.scenes,
      projectSlug: payload.projectSlug,
    });
  },

  async getMomentPacks(
    { commit, dispatch, state },
    { projectSlug }: IGetMomentPacksPayload,
  ) {
    const momentPacks: IPack[] =
      (await this.$filmApiService.getPacks(projectSlug)) || [];
    await dispatch('updateMomentPacks', { momentPacks, projectSlug });
  },

  async updateMomentPacks(
    { commit, dispatch, state },
    payload: { momentPacks: IPack[]; projectSlug: string },
  ) {
    commit('updateMomentPacks', {
      momentPacks: payload.momentPacks,
      projectSlug: payload.projectSlug,
    });
  },

  async getCollectibles(
    { commit, dispatch, state },
    { projectSlug }: IGetCollectiblesPayload,
  ) {
    const collectibles =
      (await this.$filmApiService.getCollectibles(projectSlug)) || [];
    await dispatch('updateCollectibles', { collectibles, projectSlug });
  },

  async updateCollectibles(
    { commit, dispatch, state },
    payload: { collectibles: ICollectible[]; projectSlug: string },
  ) {
    commit('updateCollectibles', {
      collectibles: payload.collectibles,
      projectSlug: payload.projectSlug,
    });
  },

  async getSceneStats(
    { commit, dispatch, state },
    { projectSlug, sceneSlug }: IGetSceneStatsPayload,
  ) {
    const sceneStats = await this.$filmApiService.getSceneStats(
      projectSlug,
      sceneSlug,
    );
    await dispatch('updateSceneStats', {
      sceneStats: {
        totalRewards: (Number(sceneStats.totalRewards) || 0)?.toFixed?.(2) || 0,
        lastDayRewards:
          (Number(sceneStats.lastDayRewards) || 0)?.toFixed?.(2) || 0,
        distroBoost: sceneStats.distroBoost,
      },
      projectSlug,
      sceneSlug,
    });
    for (const momentId of Object.keys(sceneStats?.momentRewards ?? {})) {
      await dispatch('updateMomentStats', {
        momentStats: sceneStats?.momentRewards?.[momentId],
        projectSlug,
        momentId,
      });
    }
  },

  async updateSceneStats(
    { commit, dispatch, state },
    payload: {
      sceneStats: ISceneStats;
      sceneSlug: string;
      projectSlug: string;
    },
  ) {
    const scenePayload = {
      sceneStats: {
        totalRewards:
          (Number(payload?.sceneStats?.totalRewards) || 0)?.toFixed?.(2) || 0,
        lastDayRewards:
          (Number(payload?.sceneStats?.lastDayRewards) || 0)?.toFixed?.(2) || 0,
        distroBoost: payload?.sceneStats?.distroBoost || 0,
      },
      projectSlug: payload.projectSlug,
      sceneSlug: payload.sceneSlug,
    };
    commit('updateSceneStats', scenePayload);
  },

  async updateMomentStats(
    { commit, dispatch, state },
    payload: {
      momentStats: IFilmMomentStatsOverview;
      momentId: string;
      projectSlug: string;
    },
  ) {
    commit('updateMomentStats', {
      momentStats: payload?.momentStats,
      projectSlug: payload.projectSlug,
      momentId: payload.momentId,
    });
  },

  async loadAllCollections({ commit, dispatch, state }, payload) {
    const collections = await this.$filmApiService.loadAllCollections();
    const { projectCollections, miscCollection, filmItems } = collections ?? {};
    const miscItems = miscCollection?.items ?? [];
    commit('updateMiscItems', { items: miscItems });
    commit('updateFilmItems', { items: filmItems });

    const entries: Array<[string, IFilmItemCollection]> = Object.entries(
      projectCollections ?? {},
    );
    for (const [projectSlug, projectCollection] of entries) {
      await dispatch('updateProjectStats', {
        projectStats: projectCollection.filmRewardMetrics,
        projectSlug,
      });
      commit('updateProjectItems', {
        items: projectCollection.items,
        projectSlug,
      });
      await dispatch('updateMysteryBoxes', {
        mysteryBoxes: projectCollection?.mysteryBoxes || [],
        projectSlug,
      });
      await dispatch('updateMomentPacks', {
        momentPacks: projectCollection?.momentPacks || [],
        projectSlug,
      });
    }
  },

  async loadProjectCollection(
    { commit, dispatch, state },
    { projectSlug }: IGetProjectSceneMomentsRewardDetailsPayload,
  ) {
    const prms: Array<Promise<any>> = [
      this.$filmApiService.getCollectionOverview(projectSlug), // `${this.collectionsUrl}/${projectSlug}/collection-overview`,
      this.$filmApiService.getPosters(projectSlug),
      this.$filmApiService.getScenes(projectSlug),
      this.$filmApiService.getPrizes(projectSlug),
      this.$filmApiService.getCollectibles(projectSlug),
      this.$filmApiService.getMysteryBoxes(projectSlug),
      this.$filmApiService.getPacks(projectSlug),
      this.$filmApiService.getProjectScenesRewardDetails(projectSlug), // `${this.collectionsUrl}/project/${projectSlug}/scenes/reward-details`,
    ];

    await Promise.all(prms)
      .then(async (responses) => {
        const [
          projectStats,
          posters,
          scenes,
          prizes,
          collectibles,
          mysteryBoxes,
          momentPacks,
          projectScenesRewards,
        ] = responses;

        await dispatch('updateProjectStats', { projectStats, projectSlug }); // gets project rewards
        await dispatch('updatePosters', { posters, projectSlug });
        await dispatch('updateScenes', { scenes, projectSlug });
        await dispatch('updatePrizes', { prizes, projectSlug });
        await dispatch('updateCollectibles', { collectibles, projectSlug });
        await dispatch('updateMysteryBoxes', { mysteryBoxes, projectSlug });
        await dispatch('updateMomentPacks', { momentPacks, projectSlug });

        for (const sceneSlug of Object.keys(projectScenesRewards)) {
          const sceneStats = projectScenesRewards[sceneSlug];
          await dispatch('updateSceneStats', {
            sceneStats: {
              totalRewards:
                (Number(sceneStats.totalRewards) || 0)?.toFixed?.(2) || 0,
              lastDayRewards:
                (Number(sceneStats.lastDayRewards) || 0)?.toFixed?.(2) || 0,
              distroBoost: sceneStats.distroBoost,
            },
            projectSlug,
            sceneSlug,
          });
          for (const momentId of Object.keys(sceneStats?.momentRewards || {})) {
            await dispatch('updateMomentStats', {
              momentStats: sceneStats?.momentRewards?.[momentId],
              projectSlug,
              momentId,
            });
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching collection data', error);
      })
      .finally(() => {});
    await dispatch('refreshProject', { projectSlug });
  },
  async refreshProject(
    { commit, dispatch, state },
    { projectSlug }: IGetProjectSceneMomentsRewardDetailsPayload,
  ) {
    commit('refreshProject', { projectSlug });
  },

  async initiateScenePage(
    { commit, dispatch, state },
    { projectSlug, sceneSlug }: IGetSceneAPIsPayload,
  ) {
    const prms: [Promise<Partial<IFilmSceneStatsOverview>>, Promise<IScene[]>] =
      [
        this.$filmApiService.getSceneStats(projectSlug, sceneSlug), // `${this.collectionsUrl}/${projectSlug}/scene/${sceneSlug}/stats`,
        this.$filmApiService.getScenes(projectSlug),
      ];

    await Promise.all(prms)
      .then(async (responses) => {
        const [sceneStats, scenes] = responses;

        await dispatch('updateSceneStats', {
          sceneStats: {
            totalRewards:
              (Number(sceneStats.totalRewards) || 0)?.toFixed?.(2) || 0,
            lastDayRewards:
              (Number(sceneStats.lastDayRewards) || 0)?.toFixed?.(2) || 0,
            distroBoost: sceneStats.distroBoost,
          },
          projectSlug,
          sceneSlug,
        });

        for (const momentId of Object.keys(sceneStats?.momentRewards ?? {})) {
          await dispatch('updateMomentStats', {
            momentStats: sceneStats?.momentRewards?.[momentId],
            projectSlug,
            momentId,
          });
        }
        await dispatch('updateScenes', { scenes, projectSlug });
      })
      .catch((error) => {
        console.error('Error fetching collection data', error);
      });

    await dispatch('refreshProject', { projectSlug });
  },

  async initiateDashboard(
    { commit, dispatch, state },
    {}: IGetSceneAPIsPayload,
  ) {
    const prms: [
      Promise<IUserRewardsDistribution[]>,
      Promise<IUserPopcornBuckets[]>,
      Promise<{ totalRewards: number }>,
      Promise<{ lastDayRewards: number }>,
    ] = [
      this.$filmApiService.getUserRewardDistributionSimpleOverview(),
      this.$filmApiService.getUserPopcornBuckets(),
      this.$filmApiService.getUserTotalRewardsDistribution(),
      this.$filmApiService.getUserLastDayRewardsDistribution(),
    ];

    await Promise.all(prms).then(async (responses) => {
      const [
        userRewardsDistribution,
        userPopcornBuckets,
        totalRewards,
        lastDayRewards,
      ] = responses;

      await dispatch('updateUserRewardsDistribution', {
        rewards: userRewardsDistribution,
      });
      await dispatch('updateUserPopcornBuckets', {
        buckets: userPopcornBuckets,
      });
      await dispatch('updateUserTotalRewards', totalRewards);
      await dispatch('updateUserLastDayRewards', lastDayRewards);
    });
  },

  async updateUserRewardsDistribution(
    { commit, dispatch, state },
    payload: { rewards: IUserRewardsDistribution[] },
  ) {
    commit('updateUserRewardsDistribution', { rewards: payload.rewards });
  },

  async updateUserPopcornBuckets(
    { commit, dispatch, state },
    payload: { buckets: IUserPopcornBuckets[] },
  ) {
    commit('updateUserPopcornBuckets', { buckets: payload.buckets });
  },

  async updateUserTotalRewards(
    { commit, dispatch, state },
    payload: { totalRewards: number },
  ) {
    commit('updateUserTotalRewards', payload);
  },

  async updateUserLastDayRewards(
    { commit, dispatch, state },
    payload: { lastDayRewards: number },
  ) {
    commit('updateUserLastDayRewards', payload);
  },

  /**
   * method that generates completable sets for the project
   * @param payload: 'category' for the project
   */
  async getLatestSetsByProject(
    { commit, dispatch, state },
    payload: { category: string },
  ) {
    try {
      const { category } = payload || {};
      const prms: Array<Promise<any>> = [
        this.$filmApiService.getPosters(category),
        this.$filmApiService.getScenes(category),
      ];

      const responses = await Promise.all(prms);
      const [posters, scenes] = responses;
      await dispatch('updatePosters', { posters, projectSlug: category });
      await dispatch('updateScenes', { scenes, projectSlug: category });
    } catch (error) {
      console.error('Error fetching sets data', error);
    }
  },

  async clearUserItems({ commit, dispatch, state }) {
    await this.$filmApiService.clearUserItems();
  },

  /**
   * method that takes response from completed_sets restriction
   * and updates the completed sets (posters/scenes) for the project (as completed)
   * and the shards/moments for the completed sets (as owned)
   * @param payload: 'category' for the project
   */
  async updateCompletedSets(
    { commit, dispatch, state, getters },
    payload: { items: IHydratedSet[]; category: string },
  ) {
    const { items, category } = payload || {};
    if (state.byProject[category]) {
      for (const set of items) {
        const [type, id] = set?.id?.split?.('/') ?? [];
        const entityType = set?.document?._type || type;

        if (entityType === SanityDocumentSchema.POSTER) {
          const poster: Partial<IPoster> = { isCompleted: true };
          const shards: IPoster['shards'] =
            getters?.getShardsByProjectIdPosterId?.(category, id) || [];
          if (shards?.length) {
            poster.shards = shards.map(
              (moment) => ({ ...moment, isOwned: true } as IShard),
            );
          }
          commit('updatePoster', { posterSlug: id, project: category, poster });
        } else if (entityType === SanityDocumentSchema.SCENE) {
          const scene: Partial<IScene> = { isCompleted: true };
          const moments: IScene['moments'] =
            getters?.getMomentsByProjectIdSceneId?.(category, id) || [];
          if (moments?.length) {
            scene.moments = moments.map(
              (moment) => ({ ...moment, isOwned: true } as IMoment),
            );
          }
          commit('updateScene', { sceneSlug: id, project: category, scene });
        }
      }
    }
  },

  async checkForUnopenedBoxes({ commit, dispatch, state, getters, rootState }) {
    try {
      if (this.app.apolloProvider && rootState.profile?.user.loggedIn) {
        const client = this.app.apolloProvider.defaultClient;
        const { data } = await client.query({
          query: isQualified,
          variables: {
            id: ERestrictionType.HAS_MYSTERY_BOXES_TO_OPEN,
            payload: {},
          },
          fetchPolicy: 'network-only',
        });

        const items: ISet[] = parseRestrictionResponseForUnopenedMyseryBoxes(
          data?.isQualified,
        );

        const unopenedBoxes: Record<string, ISet[]> = {};
        if (items?.length) {
          for (const item of items) {
            const category = item?.id?.split?.('|')?.[1];
            if (category) {
              if (!unopenedBoxes[category]) {
                unopenedBoxes[category] = [];
              }
              unopenedBoxes[category].push(item);
            }
          }
        }
        await dispatch('updateUnopenedBoxes', { unopenedBoxes });
      }
    } catch (error) {
      console.error(error);
    }
  },

  async updateUnopenedBoxes(
    { commit, dispatch, state, getters, rootState },
    payload: { unopenedBoxes: Record<string, ISet[]> },
  ) {
    const { unopenedBoxes } = payload || {};
    commit('updateUnopenedBoxes', { unopenedBoxes });
  },
};
