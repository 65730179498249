import { ActionTree } from 'vuex';
import {
  IRewardState,
  IAwardVideoCompletionRewardPayload,
  IClaimRewardResponse,
} from './types';
import { IRootState } from '../types';
import { IReward } from '~/types/sanity-objects';
import { IVideo } from '~/types/sanity-documents';

export const actions: ActionTree<IRewardState, IRootState> = {
  async handleVideoWatchCompletionRewards({ dispatch }, video: IVideo) {
    if (video?.completionReward) {
      dispatch('awardVideoCompletionReward', {
        reward: video.completionReward,
        videoSlug: video.slug,
      });
    }

    if (video?.musicToken) {
      dispatch('creditUserForListeningToVideoMusic', video.musicToken);
    }
  },

  async awardVideoCompletionReward(
    { commit, dispatch, rootState },
    payload: IAwardVideoCompletionRewardPayload,
  ) {
    if (!rootState.profile?.user.loggedIn) {
      return;
    }

    // call film-api and figure out if user should be rewarded an item for watching video
    const awardResponse = await this.$filmApiService.post<{
      rewarded: boolean;
      mintedItemGyriIds?: string[];
    }>('rewards/video-completion-reward', {
      videoSlug: payload.videoSlug,
    });

    if (awardResponse.rewarded && awardResponse.mintedItemGyriIds?.length) {
      // need to get the new items into our inventory
      // await delay(1000);
      await dispatch('inventory/getUserItems', null, { root: true });

      // set the current reward to the first minted item
      const currentReward: IReward = {
        ...payload.reward,
        gyriId: awardResponse.mintedItemGyriIds[0],
      };

      commit('updateCurrentReward', currentReward);
      commit('updateShowRewardSnackbar', true);
    }
  },

  async claimUserReward({ commit, dispatch, rootState }, rewardId: string) {
    if (!rootState.profile?.user.loggedIn) {
      return;
    }

    // call film-api and figure out if user can be rewarded item
    const awardResponse = await this.$filmApiService.post<IClaimRewardResponse>(
      'rewards/claim-reward',
      {
        rewardId,
      },
    );

    if (awardResponse.rewarded && awardResponse.mintedItemGyriIds?.length) {
      // need to get the new items into our inventory
      dispatch('inventory/getUserItems', null, { root: true });

      // set the current reward to the first minted item
      const currentReward: IReward = {
        gyriId: awardResponse.mintedItemGyriIds[0],
      };

      commit('updateCurrentReward', currentReward);
    }

    return awardResponse;
  },

  async canClaimUserReward({ rootState }, rewardId: string) {
    if (!rootState.profile?.user.loggedIn) {
      return false;
    }

    const canClaimUserReward = await this.$filmApiService.get<boolean>(
      `rewards/can-claim-reward/${rewardId}`,
    );

    return canClaimUserReward;
  },

  async showRewardSnackbar({ commit }, show: boolean) {
    commit('updateShowRewardSnackbar', show);
  },

  async showRewardMysteryBoxModal({ commit }, show: boolean) {
    commit('updateShowRewardMysteryBoxModal', show);
  },

  async creditUserForListeningToVideoMusic({ commit }, musicToken: string) {
    await this.$musicDataService.trackSongCompletionEvent(musicToken);
  },

  async getProjectRewards({ commit, dispatch, state }, projectSlug: string) {
    const rewardDetails = await this.$filmApiService.getProjectRewardStats(
      projectSlug,
    );
    commit('updateProjectRewards', { rewardDetails, projectSlug });
  },
};
