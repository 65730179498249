import { render, staticRenderFns } from "./PromoBackground.vue?vue&type=template&id=e028ff6a&scoped=true&"
import script from "./PromoBackground.vue?vue&type=script&lang=ts&setup=true&"
export * from "./PromoBackground.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./PromoBackground.vue?vue&type=style&index=0&id=e028ff6a&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e028ff6a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SanityResponsiveImage: require('/builds/gala-games/gala-film/gala-film/src/components/Sanity/SanityResponsiveImage.vue').default})
