import { MutationTree } from 'vuex';
import Vue from 'vue';
import { IRewardState } from './types';
import { IReward } from '~/types/sanity-objects';
import { IFilmRewardMetrics } from '~/types/my-collection';

export const mutations: MutationTree<IRewardState> = {
  updateShowRewardSnackbar(rewardState: IRewardState, showSnackbar: boolean) {
    rewardState.showRewardSnackbar = showSnackbar;
  },

  updateCurrentReward(rewardState: IRewardState, reward?: IReward) {
    rewardState.currentReward = reward;
  },

  updateShowRewardMysteryBoxModal(rewardState: IRewardState, show: boolean) {
    rewardState.showRewardMysteryBoxModal = show;
  },

  updateProjectRewards(
    rewardState: IRewardState,
    payload: {
      projectSlug: string;
      rewardDetails: IFilmRewardMetrics;
    },
  ) {
    Vue.set(rewardState.project, payload.projectSlug, payload.rewardDetails);
  },
};
