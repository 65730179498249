import { render, staticRenderFns } from "./LanguageSwitcher.vue?vue&type=template&id=b5448ff2&scoped=true&"
import script from "./LanguageSwitcher.vue?vue&type=script&lang=ts&"
export * from "./LanguageSwitcher.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5448ff2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSelect: require('/builds/gala-games/gala-film/gala-film/src/components/LanguageSwitcher/LanguageSelect.vue').default})
