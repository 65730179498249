import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { CurrentUser } from '@sanity/types/dist/dts';
import tokenMigrationStatusQuery from '../queries/tokenMigrationStatus.gql';
import { SanityDataset } from '~/types/sanity';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import { ISanityObjects, SanityObjectSchema } from '~/types/sanity-objects';
import { IFeatureFlags, IFeatureFlagsState } from '~/utils/featureFlags';
import {
  IRootState,
  ICreateWalletPrompt,
  ICurrentPage,
  IPreviousPage,
  IUserAgentInfo,
  ISnackbarPayload,
  ThemeName,
  TokenMigrationStatus,
  IDebugOptions,
  TUpdateSanityUserPayload,
  TClearDocumentsCachesPayload,
  IPromptToLoginPayload,
  IPromptToRegisterPayload,
  WalletType,
} from './types';
import profile from './profile/index';
import inventory from './inventory/index';
import games from './games/index';
import share from './share';
import notifications from './notifications';
import nodes from './nodes';
import customReveal from './customReveal';
import sanityDocumentModules from './documents';
import reference from './reference';
import collections from './collections';
import ufc from './ufc';
import projects from './projects';
import myTheater from './myTheater';
import rewards from './rewards';
import {
  TDocStoreMutationPayloads,
  TDocumentStoreModulesStates,
} from './documents/types';
import { TReferencePayloads } from './reference/types';
import web3Wallet from './web3_wallet';
import { setRedirectAfterLogin } from '~/utils/redirectAfterLogin';
import {
  getAuthThemeParams,
  getAuthThemeDataFromRoute,
  getAuthRedirectPathFromRoute,
} from '~/utils/authTheme';

Vue.use(Vuex);

export const initialSiteConfig = {
  _id: '',
  _type: SanityDocumentSchema.SITE_CONFIG as SanityDocumentSchema.SITE_CONFIG,
  _createdAt: '',
  _updatedAt: '',
  _rev: '',
  ...(process.env.siteConfigFromEnv as unknown as IFeatureFlags),
};

const empty: Partial<Record<`documents.${SanityDocumentSchema}`, any>> = {};
const sanityDocumentsModulesNamespaced = Object.entries(
  sanityDocumentModules,
).reduce((modules, [type, module]) => {
  const docType = type as SanityDocumentSchema;
  modules[`documents.${docType}`] = module;
  return modules;
}, empty);

const store: StoreOptions<IRootState> = {
  state: {
    version: '1.0.0',
    showSnackbar: false,
    showSuccessSnackbar: false,
    showErrorSnackbar: false,
    createWalletPrompt: {
      show: false,
      walletType: WalletType.ETH,
    },
    showWeb3WalletErrorDialog: false,
    showFilmEmailSignup: false,
    showDiscordDialog: false,
    showDiscordConnectingDialog: false,
    showReferralDialog: false,
    showHeader: true,
    showFooter: true,
    snackbarErrorText: 'Something went wrong.',
    snackbarSuccessText: 'Success.',
    snackbarText: '',
    snackbarOptions: {},
    currentPage: { title: '', showBoxCoinBalance: false },
    previousPage: { path: '/', sameAsCurrent: true },
    discordLink: 'https://discord.gg/cC8Mg9HwDf',
    userAgentInfo: { language: 'en-US', displayMode: 'browser' },
    apiErrorCodes: [],
    showResetDialog: false,
    theme: ThemeName.DARK,
    tokenMigrationStatus: TokenMigrationStatus.inactive,
    debugSettings: {
      debugSettingsEnabled: false,
      contentDataset: process.env.sanityDataset as SanityDataset,
      settingsDataset: process.env.sanityDataset as SanityDataset,
      shouldShowDrafts: !!process.env.sanityWithCredentials,
    },
    sanityUser: {
      isLoading: false,
      hasError: false,
    },
    siteConfig: {
      isLoading: false,
      hasError: false,
      data: initialSiteConfig,
    },
    siteConfigSlug: process.env.sanitySiteConfigSlug || '',
    redirects: false,
    hasClosedToolbarMarketingBanner: false,
  },
  modules: {
    profile,
    inventory,
    games,
    share,
    notifications,
    nodes,
    customReveal,
    reference,
    web3Wallet,
    collections,
    ufc,
    projects,
    myTheater,
    rewards,
    ...sanityDocumentsModulesNamespaced,
  },
  mutations: {
    toggleSnackbar(rootState: IRootState, payload?: boolean) {
      rootState.showSnackbar =
        payload === undefined || payload === null
          ? !rootState.showSnackbar
          : payload;
    },
    toggleSuccessSnackbar(rootState: IRootState, payload?: boolean) {
      rootState.showSuccessSnackbar =
        payload === undefined || payload === null
          ? !rootState.showSuccessSnackbar
          : payload;
    },
    toggleErrorSnackbar(rootState: IRootState, payload?: boolean) {
      rootState.showErrorSnackbar =
        payload === undefined || payload === null
          ? !rootState.showErrorSnackbar
          : payload;
    },
    toggleCreateWalletPrompt(
      rootState: IRootState,
      payload?: Partial<ICreateWalletPrompt>,
    ) {
      rootState.createWalletPrompt = {
        ...(payload || {}),
        show: payload?.show ?? !rootState.createWalletPrompt.show,
        walletType: payload?.walletType || WalletType.ETH,
      };
    },
    toggleWeb3WalletErrorDialog(rootState: IRootState, payload?: boolean) {
      rootState.showWeb3WalletErrorDialog =
        payload ?? !rootState.showWeb3WalletErrorDialog;
    },
    toggleFilmEmailSignup(rootState: IRootState, payload?: boolean) {
      rootState.showFilmEmailSignup =
        payload === undefined || payload === null
          ? !rootState.showFilmEmailSignup
          : payload;
    },
    toggleDiscordDialog(rootState: IRootState, payload?: boolean) {
      rootState.showDiscordDialog =
        payload === undefined || payload === null
          ? !rootState.showDiscordDialog
          : payload;
    },
    toggleDiscordConnectingDialog(rootState: IRootState, payload?: boolean) {
      rootState.showDiscordConnectingDialog =
        payload === undefined || payload === null
          ? !rootState.showDiscordConnectingDialog
          : payload;
    },
    toggleReferralDialog(rootState: IRootState, payload?: boolean) {
      rootState.showReferralDialog =
        payload === undefined || payload === null
          ? !rootState.showReferralDialog
          : payload;
    },
    toggleHeader(rootState: IRootState, payload?: boolean) {
      rootState.showHeader =
        payload === undefined || payload === null
          ? !rootState.showHeader
          : payload;
    },
    toggleFooter(rootState: IRootState, payload?: boolean) {
      rootState.showFooter =
        payload === undefined || payload === null
          ? !rootState.showFooter
          : payload;
    },
    updateSnackbar(rootState: IRootState, payload?: ISnackbarPayload) {
      if (!payload || Object.keys(payload).length === 0) {
        rootState.snackbarOptions = {};
        rootState.snackbarText = '';
        return;
      }

      if (typeof payload === 'string') {
        rootState.snackbarOptions = {};
        rootState.snackbarText = payload;
        return;
      }

      const { message, ...options } = payload;
      rootState.snackbarOptions = options;
      rootState.snackbarText = message || '';
    },
    updateSnackbarSuccessText(rootState: IRootState, payload: string) {
      rootState.snackbarSuccessText = payload;
    },
    updateSnackbarErrorText(rootState: IRootState, payload: string) {
      rootState.snackbarErrorText = payload;
    },
    updateCurrentPage(rootState: IRootState, payload: ICurrentPage) {
      rootState.currentPage = payload;
    },
    updatePreviousPage(rootState: IRootState, payload: IPreviousPage) {
      rootState.previousPage = payload;
    },
    updateUserAgentInfo(rootState: IRootState, payload: IUserAgentInfo) {
      rootState.userAgentInfo = payload;
    },
    addApiErrorIds(rootState: IRootState, payload: string[]) {
      const timestamp = new Date().toISOString();
      const payloadWithTimestamp = payload.map((id) => ({ id, timestamp }));
      rootState.apiErrorCodes = [
        ...payloadWithTimestamp,
        ...rootState.apiErrorCodes,
      ].slice(0, 10);

      localStorage.setItem(
        'apiErrorIds',
        JSON.stringify(rootState.apiErrorCodes),
      );
    },
    updateTheme(rootState: IRootState, payload: ThemeName) {
      rootState.theme = payload;
    },
    updateTokenMigrationStatus(
      rootState: IRootState,
      payload: TokenMigrationStatus,
    ) {
      rootState.tokenMigrationStatus = payload;
    },
    updateDebugSettings(rootState: IRootState, payload: IDebugOptions) {
      rootState.debugSettings = payload;
    },
    updateSanityUser(rootState: IRootState, payload: TUpdateSanityUserPayload) {
      const { type, ...newState } = payload;
      rootState.sanityUser = newState;
    },
    updateSiteConfig(rootState: IRootState, payload: IFeatureFlagsState) {
      rootState.siteConfig = payload;
    },
    updateRedirects(
      rootState: IRootState,
      payload: Record<string, ISanityObjects[SanityObjectSchema.REDIRECT_TO]>,
    ) {
      rootState.redirects = payload;
    },
    updateHasClosedToolbarMarketingBanner(
      rootState: IRootState,
      payload: boolean,
    ) {
      rootState.hasClosedToolbarMarketingBanner = payload;
    },
  },
  actions: {
    async promptToRegister({}, payload: IPromptToRegisterPayload) {
      if (payload?.activeCampaignId) {
        localStorage.setItem(
          'signupActiveCampaignId',
          payload.activeCampaignId,
        );
      }

      if (payload?.redirectAfterLoginPath) {
        setRedirectAfterLogin(payload?.redirectAfterLoginPath);
      } else {
        const redirect = await getAuthRedirectPathFromRoute(
          this.app.context.route,
          this.$sanityLookup,
        );
        if (redirect) {
          setRedirectAfterLogin(redirect);
        }
      }

      const theme =
        payload.theme ||
        getAuthThemeDataFromRoute(this.app.context.route, this.$sanityLookup);
      const extParams = theme
        ? getAuthThemeParams(theme, this.$sanityImage)
        : {};

      return this.$auth.loginWithRedirect({
        authorizationParams: {
          ...extParams,
          prompt: 'login',
          screen_hint: 'signup',
          connection: payload.connection,
        },
      });
    },
    async promptToLogin({}, payload: IPromptToLoginPayload) {
      if (payload?.redirectAfterLoginPath) {
        setRedirectAfterLogin(payload?.redirectAfterLoginPath);
      } else {
        const redirect = await getAuthRedirectPathFromRoute(
          this.app.context.route,
          this.$sanityLookup,
        );
        if (redirect) {
          setRedirectAfterLogin(redirect);
        }
      }

      const theme =
        payload.theme ||
        getAuthThemeDataFromRoute(this.app.context.route, this.$sanityLookup);
      const extParams = theme
        ? getAuthThemeParams(theme, this.$sanityImage)
        : {};
      return this.$auth.loginWithRedirect({
        authorizationParams: {
          ...extParams,
          prompt: 'login',
        },
      });
    },
    async clearReferenceCache({ commit }) {
      commit<TReferencePayloads['clearCache']>({
        type: `reference/clearCache`,
      });
    },

    async clearDocumentsCaches(
      { commit },
      payload: TClearDocumentsCachesPayload,
    ) {
      const types = payload?.types?.length
        ? payload.types
        : Object.keys(sanityDocumentModules);
      types?.forEach((type) => {
        const isModule =
          !!sanityDocumentModules[type as keyof typeof sanityDocumentModules];
        if (!isModule) {
          return;
        }
        const schema = type as SanityDocumentSchema;
        commit<TDocStoreMutationPayloads['clearCache']>({
          type: `documents.${schema}/clearCache`,
        });
      });
    },

    async clearAllCaches({ commit, dispatch }) {
      commit<TReferencePayloads['clearCache']>({
        type: `reference/clearCache`,
      });

      Object.keys(sanityDocumentModules).forEach((key) => {
        const docType = key as SanityDocumentSchema;
        commit<TDocStoreMutationPayloads['clearCache']>({
          type: `documents.${docType}/clearCache`,
        });
      });
    },
    async getSanityUser({ commit }) {
      if (!this.app.$sanity?.client?.users) {
        return;
      }
      try {
        commit<TUpdateSanityUserPayload>({
          type: 'updateSanityUser',
          isLoading: true,
          hasError: false,
        });
        const user = await this.app.$sanity.client.users.getById('me');
        commit<TUpdateSanityUserPayload>({
          type: 'updateSanityUser',
          isLoading: false,
          hasError: false,
          data: user ? (user as CurrentUser) : undefined,
        });
      } catch (e) {
        commit({
          type: 'updateSanityUser',
          isLoading: false,
          hasError: true,
          error: e,
        });
      }
    },
    async getSiteConfig(
      { commit, dispatch, state },
      payload: { bypassCache?: boolean },
    ) {
      if (!this.app.$sanity?.client) {
        commit('updateSiteConfig', {
          isLoading: false,
          hasError: false,
          data: { ...initialSiteConfig },
        });
        return;
      }

      const slug = state.siteConfigSlug;
      const _type = SanityDocumentSchema.SITE_CONFIG;
      await this.app.$sanityLookup.fetchDocument({
        _type,
        slug,
        bypassCache: payload?.bypassCache,
      });

      const doc = this.app.$sanityLookup.getDocument<
        ISanityDocuments[SanityDocumentSchema.SITE_CONFIG]
      >({ _type, slug });
      if (doc?.data?.redirects?.length) {
        const redirectsReduced = doc.data.redirects.reduce(
          (
            acc: Record<string, ISanityObjects[SanityObjectSchema.REDIRECT_TO]>,
            redirect,
          ) => {
            if (
              !redirect?.redirectFrom ||
              /^https?:\/\//.test(redirect.redirectFrom) ||
              !redirect.redirectTo
            ) {
              return acc;
            }

            const { redirectFrom, redirectTo } = redirect;
            if (
              !redirectTo.linkType ||
              (redirectTo.linkType === 'url' && !redirectTo.href) ||
              (redirectTo.linkType === 'docRef' &&
                !redirectTo.documentReference?._ref)
            ) {
              return acc;
            }

            try {
              const from = new URL(redirectFrom, window.location.origin);
              if (!from.pathname || !!acc[from.pathname]) {
                return acc;
              }
              acc[from.pathname] = redirectTo;
            } catch (e) {
              return acc;
            }
            return acc;
          },
          {},
        );
        commit('updateRedirects', redirectsReduced);
      }

      if (doc?.data?.auth?._ref) {
        this.app.$sanityLookup.fetchDocument({
          _type: SanityDocumentSchema.AUTH_CONFIG,
          _id: doc.data.auth._ref,
          bypassCache: payload?.bypassCache,
        });
      }
    },
    async getTokenMigrationStatus({ commit }) {
      if (this.app.apolloProvider) {
        try {
          const client = this.app.apolloProvider.defaultClient;

          const { data } = await client.query({
            query: tokenMigrationStatusQuery,
            fetchPolicy: 'cache-first',
          });

          if (
            data &&
            data.tokenMigrationStatus &&
            data.tokenMigrationStatus.status
          ) {
            commit(
              'updateTokenMigrationStatus',
              data.tokenMigrationStatus.status,
            );
          }
        } catch (error) {}
      }
    },
    async showErrorSnackbarMessage({ commit }, message: string) {
      commit('updateSnackbarErrorText', message);
      commit('toggleErrorSnackbar', true);
    },
  },
  getters: {
    showBuyCoinScreens(rootState: IRootState) {
      if (process.env.showBuyCoinScreens === 'true') {
        return true;
      }
      if (process.env.showBuyCoinScreens === 'admin') {
        if (rootState.profile) {
          return rootState.profile.user.role === 'admin';
        }
      }
      return false;
    },
    showItemLockingFeature(rootState: IRootState) {
      const stagesVisible = [
        TokenMigrationStatus.locking,
        TokenMigrationStatus.ready,
        TokenMigrationStatus.active,
      ];
      return stagesVisible.some(
        (stage) => stage === rootState.tokenMigrationStatus,
      );
    },
    tokenMigrationActive(rootState: IRootState) {
      return rootState.tokenMigrationStatus === TokenMigrationStatus.active;
    },
    siteConfig(rootState: IRootState) {
      const defaultConfigState = {
        isLoading: false,
        hasError: false,
        data: {
          ...initialSiteConfig,
        },
      };

      if (!rootState.siteConfigSlug) {
        return defaultConfigState;
      }

      const rootStateWithDocStore = rootState as IRootState &
        TDocumentStoreModulesStates;
      const siteConfigStore =
        rootStateWithDocStore[`documents.${SanityDocumentSchema.SITE_CONFIG}`];
      const slugState = siteConfigStore?.slugs?.[rootState.siteConfigSlug];
      if (slugState.isLoading) {
        return {
          isLoading: true,
          hasError: false,
        };
      }

      const siteConfigId = slugState?.key?.replace(/drafts./, '') || undefined;
      if (!siteConfigId) {
        return defaultConfigState;
      }

      return (
        siteConfigStore?.documents?.[`drafts.${siteConfigId}`] ||
        siteConfigStore?.documents?.[siteConfigId] ||
        defaultConfigState
      );
    },
  },
};

export default () => new Vuex.Store<IRootState>(store);
