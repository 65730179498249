import { render, staticRenderFns } from "./external.vue?vue&type=template&id=590342d8&"
import script from "./external.vue?vue&type=script&lang=ts&"
export * from "./external.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardSnackbar: require('/builds/gala-games/gala-film/gala-film/src/components/Dashboard/DashboardSnackbar.vue').default})
