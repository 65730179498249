
import { Component, Mixins } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { ExternalWallet, AuthQuery } from '~/mixins';
import capitalize from '~/utils/capitalize';
import { walletErrorTitleFromCode } from '~/utils/walletConnectionError';

@Component({})
export default class WalletConnectionErrorBanner extends Mixins(
  ExternalWallet,
  AuthQuery,
) {
  @Mutation updateSnackbarErrorText!: (payload: string) => void;
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;

  dappPath = window.location.href.toString().replace(/(http[s]*:\/\/)/, '');
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  get providerLabel() {
    return (
      this.walletConnectionError?.provider ??
      this.connectedExternalWallet.walletProvider ??
      ''
    );
  }

  get providerDisplayLabel() {
    return this.selectedProviderOption?.name ?? capitalize(this.providerLabel);
  }

  get selectedProviderOption() {
    return this.providerOptions.find(
      (option) => option.label === this.providerLabel,
    );
  }

  get missingProviderLink() {
    if (this.selectedProviderOption) {
      return this.isMobile
        ? this.selectedProviderOption.missingProviderMobileLink(this.dappPath)
        : this.selectedProviderOption.missingProviderDesktopLink;
    }
    return '';
  }

  get heading() {
    if (this.walletConnectionError?.shortMessage) {
      return this.walletConnectionError?.shortMessage;
    }

    return this.walletConnectionError?.code
      ? walletErrorTitleFromCode(
          this.walletConnectionError.code,
          this.$i18n,
          this.providerDisplayLabel,
        )
      : this.walletConnectionError?.message;
  }

  get body() {
    // @TODO: If browser is missing provider,
    // but they have a different provider with an address matching
    // state.connectedExternalWallet.ethAddress, should we let them connect with that?

    // Maybe we ask if they'd like to connect with the correct address with the
    // other provider and update the backend to handle doing this without making the user
    // disconnect with the original provider?

    // provide better messaging to user based on whether they're on
    // a mobile device (where they can install the provider app)
    // or desktop (where they can install the browser extension)
    if (
      this.walletConnectionError?.code ===
      this.walletConnectionErrorCode.MISSING_PROVIDER
    ) {
      const prefix =
        'components.wallet.connectWeb3Wallet.installProviderDialog';
      return this.providerDisplayLabel
        ? this.$t(
            `${prefix}.${this.isMobile ? 'mobileBody' : 'bodyWithName'}`,
            {
              provider: this.providerDisplayLabel,
            },
          ).toString()
        : this.$t(`${prefix}.body`).toString();
    }

    return this.walletConnectionError?.message === this.heading
      ? ''
      : this.walletConnectionError?.message;
  }

  get ctaProps() {
    switch (this.walletConnectionError?.code) {
      case this.walletConnectionErrorCode.MISSING_PROVIDER:
        return {
          nuxt: true,
          target: '_blank',
          href: this.missingProviderLink,
        };

      case this.walletConnectionErrorCode.MISSING_BROWSER_CONNECTION:
        return {};
      default:
        return null;
    }
  }

  get ctaListeners() {
    switch (this.walletConnectionError?.code) {
      case this.walletConnectionErrorCode.MISSING_BROWSER_CONNECTION:
        return {
          click: this.handleConnectClick,
        };
      default:
        return {};
    }
  }

  get ctaText() {
    switch (this.walletConnectionError?.code) {
      case this.walletConnectionErrorCode.MISSING_PROVIDER:
        return this.$t(
          'components.wallet.connectWeb3Wallet.installProviderDialog.goToWebsite',
          {
            provider: this.providerDisplayLabel,
          },
        );
      case this.walletConnectionErrorCode.MISSING_BROWSER_CONNECTION:
        return this.$t('components.home.walletConnectionErrorBanner.connect');
      default:
        return null;
    }
  }

  created() {
    if (
      this.walletConnectionError?.code ===
      this.walletConnectionErrorCode.MISSING_PROVIDER
    ) {
      this.handleMissingProvider();
    }
  }

  async handleConnectClick() {
    const response = await this.connect(
      {
        label: this.connectedExternalWallet.walletProvider,
        connectToUser: false,
      },
      this.doAuthQuery,
    );

    if (response.success) {
      this.$emit('resolved');
      return;
    }

    if (!response.success && response.message) {
      this.updateSnackbarErrorText(response.message);
      this.toggleErrorSnackbar();
    }
  }

  async handleMissingProvider() {
    if (this.selectedProviderOption) {
      if (this.isMobile && !window.location.href.includes('wb=true')) {
        // This might not work on all mobile devices for all web3 wallet apps. Some require the deep link to be initiated by a user action like a click. This link is provided in the install modal that gets shown
        window.open(
          this.selectedProviderOption.missingProviderMobileLink(this.dappPath),
          '_blank',
        );
      }
    }
  }
}
