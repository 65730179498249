
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Mutation, State, Action, Getter } from 'vuex-class';
import _groupBy from 'lodash.groupby';
import RequirePasscode from '~/components/Wallet/RequirePasscode.vue';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import exchangeGyriTokenMutation from '~/mutations/exchangeGyriToken.gql';
import exchangeOffChainTokenMutation from '~/mutations/exchangeOffChainToken.gql';
import bulkHasTransferLockQuery from '~/queries/bulkHasTransferLock.gql';
import AuthQuery from '~/mixins/AuthQuery';
import {
  ActionType,
  TCustomRevealUserItem,
  IBulkLockedItem,
  UserItem,
} from '~/types/user-items';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import {
  EExchangeState,
  IExchangeReward,
  ISet,
  ERestrictionType,
  EUnlockableSets,
  IHydratedSet,
} from '~/store/customReveal/types';
import { ChainNetwork } from '~/types/chain';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import {
  getEventBucket,
  getRestrictionUpsertEventId,
  delay,
  isCustomRevealExchangeItem,
  isSimilarItem,
} from '~/utils/custom-reveal';
import CustomExchangeReveal from '~/components/Items/CustomExchangeReveal.vue';
import { ICreateWalletPrompt, WalletType } from '~/store/types';
import { parseRestrictionResponseForCompletedSets } from '~/utils/collections';
import isQualified from '~/queries/isQualified.gql';
import { CustomRevealEventTypes } from '~/types/event-restriction';
import { getItemGyriId } from '~/utils/userItem';

@Component({
  components: {
    RequirePasscode,
    FullScreenDialog,
    CustomExchangeReveal,
    TwoFactorAuthInputPrompt,
  },
})
export default class CustomExchangeRevealUserItem extends Mixins(AuthQuery) {
  @Prop(Object) readonly item!: TCustomRevealUserItem;

  @State((profile) => profile.user.id, { namespace: 'profile' })
  userId!: string;

  @State((inventory) => inventory.userItems, { namespace: 'inventory' })
  readonly userItems!: TCustomRevealUserItem[];

  @Getter('currentExchangeState', { namespace: 'customReveal' })
  readonly exchangeState!: EExchangeState;

  @Action('setExchangeState', { namespace: 'customReveal' })
  private setExchangeState!: (payload: EExchangeState) => void;

  @Action('setRewards', { namespace: 'customReveal' })
  private setRewards!: (payload: IExchangeReward[]) => void;

  @Action('setVideoReady', { namespace: 'customReveal' })
  private setVideoReady!: (payload: boolean) => void;

  @Action('setVideoBoxType', { namespace: 'customReveal' })
  private setVideoBoxType!: (payload: string) => void;

  @Action('setNewlyCompletedSets', { namespace: 'customReveal' })
  private setNewlyCompletedSets!: (payload: IHydratedSet[]) => void;

  @Action('updateCompletedSets', { namespace: 'collections' })
  private updateCompletedSets!: (payload: {
    items: IHydratedSet[];
    category: string;
  }) => void;

  @Action('getLatestSetsByProject', { namespace: 'collections' })
  private getLatestSetsByProject!: (payload: any) => void;

  @Action('clearUserItems', { namespace: 'collections' })
  private clearUserItems!: () => void;

  @Action('loadProjectCollection', { namespace: 'collections' })
  private loadProjectCollection!: (payload: { projectSlug: string }) => void;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: string) => void;

  @Mutation('toggleCreateWalletPrompt')
  private toggleCreateWalletPrompt!: (
    payload?: Partial<ICreateWalletPrompt>,
  ) => void;

  @Getter('getUnlockableSets', { namespace: 'collections' })
  readonly getUnlockableSets!: (
    category: string,
    gyriIdsFilter: string[],
  ) => ISet[];

  @Getter('getItemsByGryiId', { namespace: 'inventory' })
  readonly getItemsByGryiId!: (gyriId: string) => UserItem[];

  encryptionPasscode = '';
  prevSelectedItem: TCustomRevealUserItem | null = null;
  selectedItem_: TCustomRevealUserItem | null = null;
  similarItems: TCustomRevealUserItem[] = [];
  isLoadingSimilarItems = false;
  noItemsText = 'You have no items to open.';
  initialOpen = true;
  defaultCustomExchangeRevealSlug = 'custom-exchange-reveal-default';

  // Fetch Marketing Banner for empty inventory if exists
  get noItemDocState() {
    const banner = this.$sanityLookup.getDocumentFromSlug({
      _type: SanityDocumentSchema.MARKETING_BANNER,
      slug: this.noItemDocStateSlug,
    }) || {
      isLoading: false,
      hasError: false,
    };

    if (banner.hasError || !banner.data) {
      const defaultBanner = this.$sanityLookup.getDocumentFromSlug({
        _type: SanityDocumentSchema.MARKETING_BANNER,
        slug: this.defaultCustomExchangeRevealSlug,
      }) || {
        isLoading: false,
        hasError: false,
      };
      return defaultBanner;
    }
    return banner;
  }

  get noItemDocStateSlug() {
    const type = this.prevSelectedItem?.gyriTokenClassKey?.type || '';
    const category =
      this.prevSelectedItem?.gyriTokenClassKey?.category || this.category || '';
    const revealType = type?.toLocaleLowerCase()?.includes('mysterybox')
      ? 'mystery-box'
      : type?.toLocaleLowerCase()?.includes('moment')
      ? 'moment-pack'
      : '';

    const bannerSlug =
      !category || !revealType
        ? this.defaultCustomExchangeRevealSlug
        : `custom-exchange-reveal-${revealType}-${category}`;

    return bannerSlug;
  }

  get doc() {
    return this.noItemDocState?.data;
  }

  get docIsLoading() {
    return this.noItemDocState?.isLoading;
  }

  get noItemsBackgroundImage() {
    if (!this.doc?.landscapeBannerImage?.asset) {
      return;
    }
    return this.$sanityImage
      .urlFor(this.doc?.landscapeBannerImage)
      .width(1920)
      .url();
  }

  get noItemsCtaText() {
    return this.doc?.ctaText;
  }

  get noItemsCtaUrl() {
    return this.doc?.ctaUrl.href;
  }

  async created() {
    try {
      this.fetchNoInvetoryBanner();
      await this.initiate();
      await this.onExchangeClick(); // auto open box on first modal load
    } catch (err) {
      console.error(err);
    } finally {
      this.initialOpen = false;
    }
  }

  @Watch('item.name', { immediate: true })
  handleItemChange(current: string | undefined, previous: string | undefined) {
    if (current !== previous && current) {
      this.initiate();
    }
  }

  async initiate() {
    this.$watch(
      'selectedItem_',
      (
        newVal: TCustomRevealUserItem | null,
        oldVal: TCustomRevealUserItem | null,
      ) => {
        if (newVal?.exchangeRevealVideo === oldVal?.exchangeRevealVideo) {
          return;
        }

        this.$nextTick(() => {
          this.setVideoReady(false);
          this.setVideoBoxType(this.selectedItem?.exchangeRevealVideo ?? '');
        });

        this.fetchNoInvetoryBanner();
      },
    );

    this.selectedItem = this.item;
    await this.getSimilarItemsToExchange();
  }

  @Watch('formattedSimilarItems', { immediate: true })
  onSimilarItemsChange(
    current?: Record<string, string>[],
    previous?: Record<string, string>[],
  ) {
    if (!!previous?.length && current?.length === 0) {
      this.noItemsText = 'You have no more items to open.';
    }
  }

  closeExchange(e: Event) {
    this.$emit('close');
  }

  closeDialog(e: Event) {
    this.setExchangeState(EExchangeState.NOT_EXCHANGING);
  }

  moveObjectToFront(
    arr: TCustomRevealUserItem[],
    key: keyof TCustomRevealUserItem,
    value: string,
  ) {
    const index = arr.findIndex((obj) => obj[key] === value);

    if (index !== -1) {
      const objectToMove = arr[index];
      arr.splice(index, 1);
      arr.unshift(objectToMove);
    }
    return arr;
  }

  goTo(e: Event, route: string) {
    this.closeExchange(e);
    this.resetBox();
    this.$router.push(route);
  }

  resetBox() {
    this.loadNextItem();
  }

  async getSimilarItemsToExchange() {
    const raritySortKey: { [key: string]: number } = {
      Ancient: 1,
      Legendary: 2,
      Epic: 3,
      Rare: 4,
      Uncommon: 5,
      Common: 6,
    };

    this.isLoadingSimilarItems = true;

    const similarItems = this.userItems.filter(
      (item) =>
        isCustomRevealExchangeItem(item) &&
        isSimilarItem(this.selectedItem, item),
    );

    // Bucket the items into groups of 49 to avoid hitting the 50 item limit which causes an error
    const bucketed = similarItems.reduce((resultArray, item, index) => {
      const bucketIndex = Math.floor(index / 49);
      if (!resultArray[bucketIndex]) {
        resultArray[bucketIndex] = [];
      }
      resultArray[bucketIndex].push(item);
      return resultArray;
    }, [] as TCustomRevealUserItem[][]);

    const tokenLockData = new Map<string, IBulkLockedItem>();

    await Promise.all(
      bucketed.map((bucket) =>
        this.getTokenLockResults(bucket).catch((err) => console.error(err)),
      ),
    ).then((bucketedResponse) => {
      bucketedResponse?.forEach((bucket) => {
        bucket?.forEach((item) => {
          if (
            item?.tokenInstanceId &&
            !tokenLockData.has(item.tokenInstanceId)
          ) {
            tokenLockData.set(item.tokenInstanceId, item);
          }
        });
      });
    });

    const filteredSimilarItems = similarItems.filter((item) => {
      const itemInstanceId = `${item.sendId}|${item.nonFungibleInstanceId}`;
      const canExchangeIfLocked = tokenLockData
        .get(itemInstanceId)
        ?.allowedActions?.includes(ActionType.EXCHANGE);
      return !tokenLockData.has(itemInstanceId) || canExchangeIfLocked;
    });

    this.similarItems = filteredSimilarItems.sort(
      (a, b) => raritySortKey[a.rarity.label] - raritySortKey[b.rarity.label],
    );
    this.isLoadingSimilarItems = false;
  }

  async getTokenLockResults(items: TCustomRevealUserItem[]) {
    const tokenInstances = items.map((instance) => {
      const { collection, category, type, additionalKey } =
        instance.gyriTokenClassKey;
      return {
        instance: instance.fungible ? '0' : instance.nonFungibleInstanceId,
        collection,
        category,
        type,
        additionalKey,
      };
    });

    const tokenLockResponse = await this.$apollo.query<{
      bulkHasTransferLock?: IBulkLockedItem[];
    }>({
      query: bulkHasTransferLockQuery,
      variables: { tokenInstances },
      fetchPolicy: 'no-cache',
    });
    return tokenLockResponse?.data?.bulkHasTransferLock ?? [];
  }

  exchangeItems(walletPassword: string) {
    const item = this.selectedItem;

    if (!item || !this.tokenId) {
      return; // no item selected or no tokenId
    } else if (item.network === ChainNetwork.GYRI && !walletPassword) {
      return; // gyri items require waller password for transfer code validation
    } else if (!isCustomRevealExchangeItem(item)) {
      return;
    }

    this.encryptionPasscode = walletPassword;
    this.$emit('exchange');
    this.setExchangeState(EExchangeState.EXCHANGE_IN_FLIGHT);

    return this.doAuthQuery(
      async (totpToken) => {
        const exchangeItemHandler = this.getExchangeItemHandler(item, {
          walletPassword,
          totpToken,
        });

        const data = await exchangeItemHandler();

        this.upsertOpeningEvent();

        return data;
      },
      TwoFaCheckpoint.transactions,
      { hideDialogDuringQuery: true },
    );
  }

  getExchangeItemHandler(
    item: TCustomRevealUserItem,
    params: Record<string, any> = {},
  ) {
    switch (item.network) {
      case ChainNetwork.GYRI:
        return () =>
          this.exchangeGyriToken({
            exchangeId: item?.gyriExchanges?.[0]?.id,
            walletPassword: params.walletPassword,
            totpToken: params.totpToken,
            tokens: [
              {
                collection: item.gyriTokenClassKey.collection,
                category: item.gyriTokenClassKey.category,
                type: item.gyriTokenClassKey.type,
                additionalKey: item.gyriTokenClassKey.additionalKey,
                instance: item.nonFungibleInstanceId,
              },
            ],
          });

      case ChainNetwork.OFF_CHAIN_TOKEN:
        return () =>
          this.exchangeOffchainToken({
            exchangeVariantId: item?.gyriExchanges?.[0]?.exchangeVariantId,
            instanceId: item.offChainTokenInstanceId,
          });

      default:
        throw new Error('Invalid network');
    }
  }

  async exchangeOffchainToken({
    exchangeVariantId,
    instanceId,
  }: {
    exchangeVariantId: string;
    instanceId: string;
  }): Promise<{
    exchangeNetwork: ChainNetwork.GYRI;
    response: { rewards?: IExchangeReward[] };
  }> {
    const res = await this.$apollo.mutate<{
      exchangeOffChainToken?: { rewards?: IExchangeReward[] };
      onRewardRestrictionResult?: {
        success: boolean;
        reason: string;
        reasonDetails: Object;
      };
    }>({
      mutation: exchangeOffChainTokenMutation,
      variables: {
        exchangeVariantId,
        instanceId,
      },
    });

    if (res?.data?.exchangeOffChainToken) {
      return {
        exchangeNetwork: ChainNetwork.GYRI,
        response: res?.data?.exchangeOffChainToken,
      };
    }

    throw new Error('Something went wrong');
  }

  async exchangeGyriToken({
    exchangeId,
    walletPassword,
    tokens,
    totpToken,
  }: {
    exchangeId: number;
    walletPassword: string;
    totpToken: string;
    tokens: Array<{
      collection: string;
      category: string;
      type: string;
      additionalKey: string;
      instance: string;
    }>;
  }): Promise<{
    exchangeNetwork: ChainNetwork.GYRI;
    response: { rewards?: IExchangeReward[] };
  }> {
    const res = await this.$apollo.mutate<{
      exchangeGyriToken?: { rewards?: IExchangeReward[] };
    }>({
      mutation: exchangeGyriTokenMutation,
      variables: {
        exchangeId,
        exchangeTokens: tokens.map(
          ({ collection, category, type, additionalKey, instance }) => ({
            tokenInstanceKey: {
              collection,
              category,
              type,
              additionalKey,
              instance,
            },
            quantity: '1',
          }),
        ),
        walletPassword,
        totpToken,
      },
    });

    if (res?.data?.exchangeGyriToken) {
      return {
        exchangeNetwork: ChainNetwork.GYRI,
        response: res?.data?.exchangeGyriToken,
      };
    }

    throw new Error('Something went wrong');
  }

  async onExchangeClick() {
    if (this.missingWallet) {
      this.showCreateWalletModal();
      return;
    }

    if (!this.encryptionPasscode && !this.isSelectedItemOffchainNetwork) {
      this.setExchangeState(EExchangeState.ENTER_PASSWORD);
      return;
    } else {
      try {
        const res = await this.exchangeItems(this.encryptionPasscode);
        if (res) {
          await this.onExchangeComplete(res);
        } else {
          throw new Error('Something went wrong');
        }
      } catch (error) {
        console.warn(error);
        this.onExchangeError({
          message: (error as Error).message,
        });
      }
    }
  }

  upsertOpeningEvent() {
    const tokenIdWithInstandId = getItemGyriId(
      this.selectedItem as UserItem,
    ) as string;
    const tokenId = getItemGyriId(
      this.selectedItem as UserItem,
      false,
    ) as string;
    const event = CustomRevealEventTypes.OPEN_IN_PROGRESS;
    this.recordEvent({
      event,
      id: getRestrictionUpsertEventId(event, tokenIdWithInstandId),
      bucket: tokenId,
    });
  }

  showCreateWalletModal() {
    const action = this.$t('pages.inventory.nfts.item.exchange') as string;
    const actionLabel = action.toLowerCase();
    const itemName = this.item?.fullName ?? this.item?.name;
    const bodyText = !actionLabel
      ? ''
      : this.missingWallet === WalletType.ETH
      ? (itemName
          ? this.$t('pages.inventory.nfts.item.setupWeb3WalletWithName', {
              action: actionLabel,
              name: itemName,
            })
          : this.$t('pages.inventory.nfts.item.setupWeb3Wallet', {
              action: actionLabel,
            })
        ).toString()
      : (itemName
          ? this.$t('pages.inventory.nfts.item.setupGyriWalletWithName', {
              action: actionLabel,
              name: itemName,
            })
          : this.$t('pages.inventory.nfts.item.setupGyriWallet', {
              action: actionLabel,
            })
        ).toString();

    this.toggleCreateWalletPrompt({
      show: true,
      walletType: this.missingWallet,
      body: bodyText,
    });
  }

  async onExchangeComplete(res: {
    exchangeNetwork:
      | ChainNetwork.ETHEREUM
      | ChainNetwork.GYRI
      | ChainNetwork.OFF_CHAIN_TOKEN;
    response: { rewards?: IExchangeReward[] };
  }) {
    this.checkForNewlyCompletedSets(res?.response?.rewards || []);
    this.setRewards(res?.response?.rewards ?? []);
    this.setExchangeState(EExchangeState.EXCHANGE_COMPLETE);
    this.$emit('exchange-complete');
  }

  onExchangeError(res: { message: string }) {
    this.encryptionPasscode = '';
    this.setExchangeState(EExchangeState.NOT_EXCHANGING);
    this.$emit('exchange-error', res.message);
    this.updateSnackbarErrorText(res.message);
    this.toggleErrorSnackbar();
  }

  loadNextItem() {
    if (this.similarItems.length > 1 && this.selectedItem) {
      const indexToRemove = this.similarItems.findIndex(
        (item) =>
          item.name === this.selectedItem?.name &&
          item.nonFungibleInstanceId ===
            this.selectedItem?.nonFungibleInstanceId,
      );

      this.similarItems.splice(indexToRemove, 1);

      this.selectedItem = this.similarItems[0];
    } else {
      this.selectedItem = null;
      this.similarItems = [];
    }
    this.setExchangeState(EExchangeState.NOT_EXCHANGING);
  }

  async checkForNewlyCompletedSets(rewards: IExchangeReward[] = []) {
    try {
      await delay(2000); // delay to allow for the new items to be loaded
      await this.clearUserItems(); // clears users old cached items
      await this.loadProjectCollection({ projectSlug: this.category }); // loads new items

      const addedItem = this.getAddedItems(rewards);
      const payload = { addedItem };
      const { data } = await this.$apollo.query({
        query: isQualified,
        variables: {
          id: this.completedSetRestrictionId,
          payload,
        },
        fetchPolicy: 'network-only',
      });

      let newlyCompletedSets: ISet[] =
        parseRestrictionResponseForCompletedSets(data?.isQualified) || [];
      if (newlyCompletedSets.length === 0) return;

      const hydratedSets = await this.hydrateCompletedSets(newlyCompletedSets);
      this.updateCompletedSets({
        items: hydratedSets,
        category: this.category,
      });
      this.setNewlyCompletedSets(hydratedSets);
      newlyCompletedSets.forEach((set) => {
        const event = CustomRevealEventTypes.SET_COMPLETED;
        this.recordEvent({
          event,
          id: getRestrictionUpsertEventId(event, set.id),
          bucket: getEventBucket(set.id),
          value: { set },
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  recordEvent(params: {
    event: string;
    id: string;
    bucket: string;
    value?: any;
  }) {
    try {
      this.$restrictionsService.upsertEvent({
        id: params.id,
        type: params.event,
        userId: this.userId,
        bucket: params.bucket,
        value: params.value || {},
        persist: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  get completedSetRestrictionId() {
    return `${ERestrictionType.COMPLETED_SET}_${this.category}`;
  }

  getAddedItems(rewards: IExchangeReward[]) {
    const addedItemsMap = rewards
      .filter(
        (item) =>
          item.collection &&
          item.category &&
          item.type &&
          item.additionalKey &&
          item.instance,
      )
      .map(
        (item) =>
          `${item.collection}|${item.category}|${item.type}|${item.additionalKey}`,
      )
      .reduce(
        (acc: Record<string, number>, tokenId: string) => (
          (acc[tokenId] = (acc[tokenId] || 0) + 1), acc
        ),
        {},
      );
    const addedItems =
      Object.entries(addedItemsMap).map(([tokenId, quantity]) => ({
        gyriId: tokenId,
        quantity,
      })) || [];
    return addedItems;
  }

  async hydrateCompletedSets(sets: ISet[]): Promise<IHydratedSet[]> {
    await Promise.all(
      sets.map(async (unlockedSet: ISet) => {
        const [_type, slug] = unlockedSet?.id?.split?.('/') as [
          SanityDocumentSchema,
          string,
        ];
        return this.$sanityLookup.fetchDocument({ _type, slug });
      }),
    );

    const newlyCompletedSets = sets
      .filter(
        (unlockedSet: ISet) =>
          unlockedSet?.id && unlockedSet?.id?.split?.('/')?.length === 2,
      )
      .filter(
        (unlockedSet: ISet) =>
          unlockedSet?.id?.split?.('/')?.[0]?.toLocaleLowerCase() in
          EUnlockableSets,
      )
      .map((unlockedSet: ISet) => {
        const [_type, slug] = unlockedSet?.id?.split?.('/') as [
          SanityDocumentSchema,
          string,
        ];
        return {
          ...unlockedSet,
          document: this.$sanityLookup.getDocument({ _type, slug })?.data,
          redirectUrl:
            unlockedSet?.id && this.category
              ? `/collection/${this.category}/${unlockedSet?.id}`
              : '',
        };
      }) as IHydratedSet[];
    return newlyCompletedSets;
  }

  fetchNoInvetoryBanner() {
    this.$sanityLookup.fetchDocument({
      _type: SanityDocumentSchema.MARKETING_BANNER,
      slug: this.noItemDocStateSlug,
    });
  }

  get buyMoreLink() {
    return this.selectedItem?.gyriTokenClassKey?.category
      ? this.$sanityLookup.getPageRoutePath({
          schema: SanityDocumentSchema.PROJECT,
          slug: this.selectedItem?.gyriTokenClassKey?.category,
        })
      : '';
  }

  get collectionLink() {
    return this.selectedItem?.gyriTokenClassKey?.category
      ? `/collection/${this.selectedItem?.gyriTokenClassKey?.category}`
      : '';
  }

  get category() {
    return this.selectedItem?.gyriTokenClassKey?.category || '';
  }

  get shouldShowPasswordModal() {
    return this.exchangeState === EExchangeState.ENTER_PASSWORD;
  }

  get isSelectedItemOffchainNetwork() {
    return this.selectedItem?.network === ChainNetwork.OFF_CHAIN_TOKEN;
  }

  get tokenId() {
    return this.selectedItem?.nonFungibleInstanceId;
  }

  get logo() {
    return this.selectedItem?.logo;
  }

  get formattedSimilarItems() {
    const filters = this.$options.filters;
    const firstItem = Array.isArray(this.$route.query.selector)
      ? this.$route.query.selector.find((v) => !!v)
      : this.$route.query.selector;

    return Object.entries(_groupBy(this.similarItems, 'name')).map(
      ([name, items]) => {
        const quantity = items.length;
        const pluralizedName =
          quantity > 1 && filters ? filters.pluralize(name) : name;
        const groupItems = firstItem
          ? this.moveObjectToFront(items, 'uniqueInventoryPath', firstItem)
          : items;

        return {
          item: groupItems[0],
          name: name,
          nameWithQuantity: `${quantity} ${pluralizedName}`,
          image: groupItems[0].icon || groupItems[0].image,
        };
      },
    );
  }

  get missingWallet() {
    return this.$walletHelper.missingWalletTypeByNetwork(this.item?.network);
  }

  get selectedItem() {
    return this.selectedItem_;
  }

  set selectedItem(item: TCustomRevealUserItem | null) {
    this.prevSelectedItem = this.selectedItem_;
    this.selectedItem_ = item;
  }
}
