import { GetterTree } from 'vuex';
import { ICollectionState } from './types';
import { IRootState } from '../types';
import _countBy from 'lodash.countby';
import _flatten from 'lodash.flatten';
import {
  IMoment,
  IScene,
  IPoster,
  IShard,
  IPack,
  IPrize,
  IMysteryBox,
  ICollectible,
  ICtaCard,
} from '~/types/collections';
import {
  IFilmCollectionOverview,
  IFilmItemCollection,
} from '~/types/my-collection';
import { IGyriUserItem, UserItem } from '~/types/user-items';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import { isCustomRevealExchangeItem } from '~/utils/custom-reveal';

export const getters: GetterTree<ICollectionState, IRootState> = {
  getPacksByProjectId: (state) => (projectSlug: string) => {
    const momentPacks: IPack[] =
      state?.byProject?.[projectSlug]?.momentPacks || [];
    return momentPacks;
  },

  getCollectiblesByProjectId: (state) => (projectSlug: string) => {
    const collectibles: ICollectible[] =
      state?.byProject?.[projectSlug]?.collectibles || [];
    return collectibles;
  },

  getPrizesByProjectId: (state) => (projectSlug: string) => {
    const prizes: IPrize[] = state?.byProject?.[projectSlug]?.prizes || [];
    return prizes;
  },

  getMysteryBoxesByProjectId: (state) => (projectSlug: string) => {
    const mysteryBoxes: IMysteryBox[] =
      state?.byProject?.[projectSlug]?.mysteryBoxes || [];
    return mysteryBoxes;
  },

  getScenesByProjectId: (state) => (projectSlug: string) => {
    const scenes: IScene[] = state?.byProject?.[projectSlug]?.scenes ?? [];
    return scenes;
  },

  getMomentsByProjectIdSceneId:
    (state) => (projectSlug: string, sceneSlug: string) => {
      const scenes: IScene[] = state?.byProject?.[projectSlug]?.scenes;
      const scene: IScene | undefined = scenes.find(
        (sceneItem) => sceneItem.sceneSlug === sceneSlug,
      );
      const moments: IMoment[] = scene?.moments || [];
      return moments;
    },

  getMomentsByProjectId: (state) => (projectSlug: string) => {
    const scenes: IScene[] = state?.byProject?.[projectSlug]?.scenes || [];
    const moments: IMoment[] = _flatten(scenes.map((scene) => scene.moments));
    return moments;
  },

  getPostersByProjectId: (state) => (projectSlug: string) => {
    const posters: IPoster[] = state?.byProject?.[projectSlug]?.posters ?? [];
    return posters;
  },

  getShardsByProjectIdPosterId:
    (state) => (projectSlug: string, posterSlug: string) => {
      const posters: IPoster[] = state?.byProject?.[projectSlug]?.posters;
      const poster: IPoster | undefined = posters.find(
        (posterItem) => posterItem.posterId === posterSlug,
      );
      const shards: IShard[] = poster?.shards || [];
      return shards;
    },

  getShardsByProjectId: (state) => (projectSlug: string) => {
    const posters: IPoster[] = state?.byProject?.[projectSlug]?.posters || [];
    const shards: IShard[] = _flatten(posters.map((poster) => poster.shards));
    return shards;
  },

  getMiscCollectionItems: (state) => {
    return state?.miscItems ?? [];
  },

  getProjects: (state) => {
    return state?.byProject ?? {};
  },

  getProjectBySlug: (state) => (projectSlug: string) => {
    const project = state?.byProject?.[projectSlug];
    return project;
  },

  getProjectCollection: (state) => (projectSlug: string) => {
    const project = state?.byProject?.[projectSlug];
    if (project) {
      const collection = {
        filmSlug: projectSlug,
        items: project?.items,
        count: project?.items?.length ?? 0,
        filmRewardMetrics: project?.projectStatsOverview,
      };
      return collection;
    }
    return null;
  },

  getProjectCollections: (state) => () => {
    const collections: IFilmItemCollection[] = [];
    const projects = state?.byProject;
    for (const [projectSlug, projectCollection] of Object.entries(
      projects ?? {},
    )) {
      const collection = {
        filmSlug: projectSlug,
        items: projectCollection?.items,
        count: projectCollection?.items?.length ?? 0,
        filmRewardMetrics: {
          totalRewards: 0,
          lastDayRewards: 0,
          ...(projectCollection?.projectStatsOverview ?? {}),
        },
      };
      collections.push(collection);
    }
    return collections;
  },

  getProjectCollectionBySlug: (state) => (projectSlug: string) => {
    const project = state?.byProject?.[projectSlug];
    const collection = {
      filmSlug: projectSlug,
      items: project?.items,
      count: project?.items?.length ?? 0,
      filmRewardMetrics: project?.projectStatsOverview,
    };
    return collection;
  },

  getProjectStatsOverviews: (state) => () => {
    const collections: {
      [projectSlug: string]: Partial<IFilmCollectionOverview>;
    } = {};
    const projects = state?.byProject;
    for (const [projectSlug, projectCollection] of Object.entries(
      projects ?? {},
    )) {
      collections[projectSlug] = projectCollection.projectStatsOverview;
    }
    return projects;
  },

  getTotalOwnedShardItemsByProject: (state) => (projectSlug: string) => {
    const posters: IPoster[] = state?.byProject?.[projectSlug]?.posters || [];
    const shards: IShard[] = _flatten(posters.map((poster) => poster.shards));
    const shardItems: UserItem[] = _flatten(shards.map((shard) => shard.items));
    return shardItems.length;
  },

  getTotalCompletedPosterCountByProject: (state) => (projectSlug: string) => {
    const posters: IPoster[] = state?.byProject?.[projectSlug]?.posters || [];
    const completedPosters = posters.filter((poster) => poster.isCompleted);
    return completedPosters.length;
  },

  getTotalPosterCountByProject: (state) => (projectSlug: string) => {
    const posters: IPoster[] = state?.byProject?.[projectSlug]?.posters || [];
    return posters.length;
  },

  getTotalCompletedScenesByProject: (state) => (projectSlug: string) => {
    const scenes: IScene[] = state?.byProject?.[projectSlug]?.scenes || [];
    const completedScenes = scenes.filter((scene) => scene.isCompleted);
    return completedScenes.length;
  },

  getTotalSceneCountByProject: (state) => (projectSlug: string) => {
    const scenes: IScene[] = state?.byProject?.[projectSlug]?.scenes || [];
    return scenes.length;
  },

  getTotalOwnedMomentItemsByProject: (state) => (projectSlug: string) => {
    const scenes: IScene[] = state?.byProject?.[projectSlug]?.scenes || [];
    const moments: IMoment[] = _flatten(scenes.map((scene) => scene.moments));
    const momentItems: UserItem[] = _flatten(
      moments.map((moment) => moment.items),
    );
    return momentItems.length;
  },

  getProjectMysteryBoxCountByProject: (state) => (projectSlug: string) => {
    const mysteryBoxes: IMysteryBox[] =
      state?.byProject?.[projectSlug]?.mysteryBoxes || [];
    return mysteryBoxes.length;
  },

  getExchangeableProjectMysteryBoxCountByProject:
    (state) => (projectSlug: string) => {
      const mysteryBoxes: IMysteryBox[] = (
        state?.byProject?.[projectSlug]?.mysteryBoxes || []
      ).filter(
        (mb: IMysteryBox) =>
          mb.item && isCustomRevealExchangeItem(mb.item as IGyriUserItem),
      );
      return mysteryBoxes.length;
    },

  getProjectMomentPackCountByProject: (state) => (projectSlug: string) => {
    const momentPacks: IPack[] =
      state?.byProject?.[projectSlug]?.momentPacks || [];
    return momentPacks.length;
  },

  getExchangeableProjectMomentPackCountByProject:
    (state) => (projectSlug: string) => {
      const momentPacks: IPack[] = (
        state?.byProject?.[projectSlug]?.momentPacks || []
      ).filter(
        (pack: IPack) =>
          pack.item && isCustomRevealExchangeItem(pack.item as IGyriUserItem),
      );
      return momentPacks.length;
    },

  getCollectionPageCtaCards: (state) => (projectSlug: string) => {
    const collectionPageAssets: ICtaCard[] =
      state?.byProject?.[projectSlug]?.projectStatsOverview?.project
        ?.collectionPage?.ctaCards ?? [];
    let ctaCards: { [type: string]: ICtaCard } = {};
    ctaCards = collectionPageAssets.reduce(
      (cards, nextCard) => ({ ...cards, [nextCard.type]: nextCard }),
      ctaCards,
    );
    return ctaCards;
  },

  areAllProjectPostersCompleted: (state) => (projectSlug: string) => {
    const posters: IPoster[] = state?.byProject?.[projectSlug]?.posters || [];
    return posters.every((poster) => poster.isCompleted);
  },

  areAllProjectScenesCompleted: (state) => (projectSlug: string) => {
    const scenes: IScene[] = state?.byProject?.[projectSlug]?.scenes || [];
    return scenes.every((scene) => scene.isCompleted);
  },

  doesProjectExist: (state) => (projectSlug: string) => {
    return !!state.byProject[projectSlug];
  },

  getByMomentStatsByProjectId: (state) => (projectSlug: string) => {
    const moments = state.byProject[projectSlug]?.byMoment ?? {};
    return moments;
  },

  getFilmCollections: (state) => {
    const projectKeys = Object.keys(state.byProject ?? {}); // .sort((a, b) => b.localeCompare(a));

    // const unopenedBoxes = state.unopenedBoxes || {};
    const collections = projectKeys.map((key) => {
      const project = state.byProject[key];
      const hasBoxes =
        project.mysteryBoxes?.length > 0 || project.momentPacks?.length > 0;
      const collection = {
        filmSlug: key,
        items: project.items,
        count: project.items.length,
        filmRewardMetrics: project.projectStatsOverview,
        // hasBoxes: unopenedBoxes[key]?.length > 0,
        hasBoxes,
      };
      return collection;
    });
    return collections;
  },

  getTotalMomentsOwnedByScene:
    (state) => (projectSlug: string, sceneSlug: string) => {
      const scenes = state.byProject[projectSlug]?.scenes ?? [];
      const scene = scenes.find(
        (sceneItem) => sceneItem.sceneSlug === sceneSlug,
      );
      const moments = scene?.moments ?? [];
      const ownedMoments = moments.reduce(
        (sum: number, moment: IMoment) => sum + +(moment.isOwned ?? 0),
        0,
      );
      return ownedMoments;
    },

  getUnlockableSets:
    (state: ICollectionState) =>
    (projectSlug: string, gyriIdsFilter: string[]) => {
      const sets = [
        ...(state.byProject?.[projectSlug]?.posters || [])
          .filter((poster) =>
            poster?.shards?.some?.((shard) =>
              gyriIdsFilter?.includes?.(shard.gyriId),
            ),
          )
          .map((expansion) => ({
            id: `${SanityDocumentSchema.POSTER}/${expansion.posterId}`,
            items:
              expansion?.shards?.map?.((shard) => ({
                gyriId: shard.gyriId,
                quantity: 1,
              })) || [],
          })),

        ...(state.byProject?.[projectSlug]?.scenes || [])
          .filter((scene) =>
            scene?.moments?.some?.((moment) =>
              gyriIdsFilter?.includes?.(moment.gyriId),
            ),
          )
          .map((scene) => ({
            id: `${SanityDocumentSchema.SCENE}/${scene.sceneSlug}`,
            items:
              scene?.moments?.map?.((moment) => ({
                gyriId: moment.gyriId,
                quantity: 1,
              })) || [],
          })),
      ];
      return sets;
    },

  getProjectSlugsFromCollections: (state: ICollectionState) => {
    return Object.keys(state.byProject) || [];
  },

  getUnopenedBoxesStatusForProjects: (state: ICollectionState) => () => {
    const statuses: Record<string, boolean> = {};

    Object.keys(state.byProject)?.forEach((projectSlug) => {
      const hasMomentBoxes =
        state.byProject[projectSlug]?.momentPacks?.length > 0;
      const hasMysteryBoxes =
        state.byProject[projectSlug]?.mysteryBoxes?.length > 0;
      statuses[projectSlug] = hasMomentBoxes || hasMysteryBoxes;
    });

    return statuses;
  },
};
