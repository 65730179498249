import numeral from 'numeral';

export const formatNumber = (value: number, formatString: string) => {
  const valWithMin = +value >= 1e-6 ? +value : 0;
  const result = numeral(valWithMin.toFixed(8)).format(formatString);
  return result;
};

export function formatChainSymbol(chain: string) {
  const filtered = chain?.replace?.(/gyri/i, 'Gala Chain');
  return filtered?.replace?.(/gyri/i, filtered) ?? '';
}

export function formatPopcornNumber(value?: number) {
  return Number(value ?? 0)?.toFixed?.(2);
}

export function formatNumberWithCommas(
  num: number | null | undefined,
  decimals = 2,
) {
  return (num || 0).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}
