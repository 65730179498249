
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { IUser } from '~/store/profile/types';
import { ThemeName } from '~/store/types';
import ShareButtons from '~/components/Share/ShareButtons.vue';
import { CopyToClipboard, TextOutput } from '~/mixins';

@Component({
  components: {
    ShareButtons,
  },
  meta: {
    theme: ThemeName.DARK,
  },
})
export default class ReferAFriend extends Mixins(CopyToClipboard, TextOutput) {
  @State((profile) => profile.user.referralCode, { namespace: 'profile' })
  readonly referralCode!: string;
  @State('showReferralDialog') readonly showReferralDialogState!: boolean;
  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @Prop({ type: String, default: '550px' })
  readonly maxWidth!: string;

  logoWidth = 0;
  theme = ThemeName.DARK;

  get showReferralDialog() {
    return this.showReferralDialogState;
  }
  set showReferralDialog(value) {
    this.toggleReferralDialog(value);
  }

  get shareUrl() {
    if (!this.referralCode) return '';

    return `https://film.gala.com?source=gala&referralCode=${this.referralCode}`;
  }

  get truncatedShareUrl() {
    const shareUrl = this.shareUrl;
    if (shareUrl) {
      const string = `${shareUrl.slice(0, 40)}${
        shareUrl?.length > 40 ? '...' : ''
      }`;
      return string;
    }
    return '';
  }

  @Mutation toggleReferralDialog!: (args: any) => void;

  async copyReferralCode() {}
}
