
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { InputValidationRules } from 'vuetify/types';
import TextField, { LabelPosition } from './TextField.vue';
import validateWalletAddress from '~/utils/validateWalletAddress';
import ScanQr from '~/components/Wallet/ScanQr.vue';

@Component({
  components: { TextField, ScanQr },
  inheritAttrs: false,
})
export default class WalletAddressInput extends Vue {
  @Prop(String) readonly inputValue!: string;
  @Prop({ type: Boolean, default: false }) readonly isInputFiat!: boolean;
  @Prop(String) readonly label!: string;
  @Prop({ type: String, default: 'inline' })
  readonly labelPosition!: LabelPosition;
  @Prop({ type: Array, default: () => [] })
  readonly rules!: InputValidationRules;
  @Prop({ type: Boolean, default: false }) readonly scanDialog!: boolean;
  @Prop(String) readonly symbol!: string;
  @Prop({ type: [String, RegExp], default: '' }) readonly validationPattern!:
    | string
    | RegExp;

  isScanning = false;
  labelPositions = LabelPosition;
  value: string | null = null;

  created() {
    this.isScanning = this.scanDialog;
    this.value = this.inputValue;
  }

  @Watch('inputValue')
  handleInputValueChange() {
    this.value = this.inputValue;
  }

  @Watch('value')
  handleValueChange(val: string) {
    this.$emit('update:inputValue', val);
  }

  get passThroughProps() {
    const props = { ...this.$attrs, ...this.$props };
    ['scanDialog', 'symbol', 'validationPattern'].forEach(
      (key) => delete props[key],
    );
    return props;
  }

  onScan(result: any) {
    this.value = result.address;
    this.isScanning = false;
    this.$emit('scanned', result);
  }

  validAddress(address: string): any {
    const isValid = validateWalletAddress(
      address,
      this.symbol,
      this.validationPattern,
    );

    return isValid
      ? true
      : this.$t('components.wallet.sendFunds.invalidAddress', {
          symbol:
            this.$options?.filters?.filterSymbolText(this.symbol) ??
            this.symbol,
        });
  }
}
