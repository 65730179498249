import { render, staticRenderFns } from "./SanityBlockContent.vue?vue&type=template&id=2ff49194&scoped=true&"
import script from "./SanityBlockContent.vue?vue&type=script&lang=ts&"
export * from "./SanityBlockContent.vue?vue&type=script&lang=ts&"
import style0 from "./SanityBlockContent.vue?vue&type=style&index=0&id=2ff49194&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff49194",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SanityContent: require('/builds/gala-games/gala-film/gala-film/node_modules/@nuxtjs/sanity/dist/components/sanity-content.js').default})
