import { render, staticRenderFns } from "./SanityResponsiveImage.vue?vue&type=template&id=1026bbc0&scoped=true&"
import script from "./SanityResponsiveImage.vue?vue&type=script&lang=ts&setup=true&"
export * from "./SanityResponsiveImage.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./SanityResponsiveImage.vue?vue&type=style&index=0&id=1026bbc0&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1026bbc0",
  null
  
)

export default component.exports