// @ts-ignore
import * as WAValidator from 'wallet-address-validator';

const validateWalletAddress = (
  address: string,
  symbol: string = 'ETH',
  validationRegex: string | RegExp = '',
): boolean => {
  if (validationRegex) {
    if (symbol === 'POPCORN[GYRI]') {
      return address === 'client|film-ops';
    }

    return RegExp(validationRegex).test(address);
  }

  if (!['BTC'].includes(symbol)) {
    symbol = 'ETH';
  }

  return WAValidator.validate(
    address,
    symbol,
    process.env.networkType || 'both',
  );
};

export default validateWalletAddress;
