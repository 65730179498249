import { ActionTree } from 'vuex';
import {
  IW3wState,
  IConnectWalletMutationOptions,
  Network,
  IProviderRpcError,
  WalletConnectionErrorCode,
  IWalletConnectionError,
} from './types';
import { IRootState, IBaseGqlResponse } from '../types';
import connectWalletMutation from '~/mutations/connectWallet.gql';
import disconnectWalletMutation from '~/mutations/disconnectWallet.gql';
import EthAddressRiskAssessment from '~/queries/ethAddressRiskAssessment.gql';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import { WalletConnectionError } from '~/utils/walletConnectionError';
import capitalize from '~/utils/capitalize';

const isErrorWithCode = (err: any): err is { code: string } => {
  return !!err?.code && typeof err.code === 'string';
};

export const actions: ActionTree<IW3wState, IRootState> = {
  async connectWalletToApp(
    { commit, dispatch, state, rootState },
    {
      label,
      connectToUser,
      twoFaWrapper,
    }: {
      label: string;
      connectToUser?: boolean;
      twoFaWrapper: <TReturnType>(
        queryDelegate: (twoFaPin: string) => Promise<TReturnType>,
        twoFaCheckpoint?: TwoFaCheckpoint,
      ) => Promise<TReturnType>;
    },
  ) {
    const providerOption = state.providerOptions.find(
      (option) => option.label === label,
    );
    const connectedProviderOption = state.connectedExternalWallet.walletProvider
      ? state.providerOptions.find(
          (option) =>
            option.label === state.connectedExternalWallet.walletProvider,
        )
      : null;
    const requestedProviderDisplayLabel =
      providerOption?.name ?? capitalize(label);
    const connectedProviderDisplayLabel = state.connectedExternalWallet
      .walletProvider
      ? connectedProviderOption?.name
      : '';

    try {
      if (!process.env.w3wConnectionEnabled) {
        const errorMessage = this.$i18n.t(
          'components.wallet.connectWeb3Wallet.errorMessages.w3wDisabled',
        ) as string;
        throw new WalletConnectionError(
          errorMessage,
          WalletConnectionErrorCode.W3W_FUNCTIONALITY_DISABLED,
        );
      }
      const provider = providerOption?.getInterface();
      if (!provider) {
        const errorMessage = this.$i18n.t(
          'components.wallet.connectWeb3Wallet.errorMessages.missingProvider',
        ) as string;
        throw new WalletConnectionError(
          errorMessage,
          WalletConnectionErrorCode.MISSING_PROVIDER,
        );
      }
      await provider.request?.({ method: 'eth_requestAccounts' });

      // @ts-ignore
      provider.on('chainChanged', () => {
        window.location.reload();
      });
      // @ts-ignore
      provider.on('accountsChanged', () => {
        window.location.reload();
      });

      const chainId = await provider.request?.({ method: 'eth_chainId' });
      const validNetwork = (await dispatch(
        'verifyNetwork',
        +chainId,
      )) as boolean;

      if (validNetwork) {
        commit('setWeb3Provider', provider);
        const linkedAccounts = await state.web3Provider!.listAccounts();
        const [address] = linkedAccounts;

        if (
          state.connectedExternalWallet.ethAddress &&
          state.connectedExternalWallet.ethAddress !== address
        ) {
          const messageKey = linkedAccounts.includes(
            state.connectedExternalWallet.ethAddress,
          )
            ? 'components.wallet.connectWeb3Wallet.errorMessages.addressMismatch'
            : 'components.wallet.connectWeb3Wallet.errorMessages.addressMismatchRequestPermissions';
          const errorMessage = this.$i18n
            .t(messageKey, {
              linkedAddress: state.connectedExternalWallet.ethAddress.slice(-4),
              provider: connectedProviderDisplayLabel,
              providerAddress: address.slice(-4),
            })
            .toString();
          throw new WalletConnectionError(
            errorMessage,
            WalletConnectionErrorCode.ADDRESS_MISMATCH,
          );
        }

        commit('setConnectedWallet', { address, label });

        const skipConnectToUser =
          state.connectedExternalWallet.ethAddress ===
            state.connectedWallet.address &&
          state.connectedExternalWallet.walletProvider ===
            state.connectedWallet.label;
        if (
          (skipConnectToUser && state.connectedExternalWallet.ethAddress) ||
          !connectToUser
        ) {
          const walletIsSafe = await dispatch('validateWalletAddressIsSafe');
          if (!walletIsSafe) {
            return {
              success: false,
              message: this.$i18n.t(
                'components.wallet.connectWeb3Wallet.errorMessages.riskyWallet',
              ),
            };
          }
        }

        if (connectToUser && !skipConnectToUser) {
          const response = await dispatch('connectWalletToUser', {
            twoFaWrapper,
          });
          if (!response.success) {
            commit('resetConnectedWallet');
            commit('resetWeb3Provider');
            throw new WalletConnectionError(
              response.message,
              WalletConnectionErrorCode.UNKNOWN,
            );
          }
          return response;
        }
        return { success: true };
      } else {
        const errorMessage = this.$i18n.t(
          'components.wallet.connectWeb3Wallet.errorMessages.invalidNetwork',
        ) as string;
        throw new WalletConnectionError(
          errorMessage,
          WalletConnectionErrorCode.INVALID_NETWORK,
        );
      }
    } catch (err) {
      this.$sentry.captureException(err);
      const error = err as WalletConnectionError;
      let message = error.message;
      let code = error.cause;
      let dialogError: IWalletConnectionError | null = null;

      switch (error.cause) {
        case WalletConnectionErrorCode.W3W_FUNCTIONALITY_DISABLED: {
          break;
        }

        case WalletConnectionErrorCode.ADDRESS_MISMATCH:
        case WalletConnectionErrorCode.GALA_WALLET_HOLDER:
        case WalletConnectionErrorCode.INVALID_NETWORK:
        case WalletConnectionErrorCode.MISSING_PROVIDER:
          dialogError = {
            code: error.cause,
            message: error.message,
            provider: connectedProviderOption?.label ?? label,
          };
          break;

        default: {
          if (error?.code === 4001) {
            code = WalletConnectionErrorCode.UNKNOWN;
            message = this.$i18n
              .t(
                'components.wallet.connectWeb3Wallet.errorMessages.connectionRejected',
              )
              .toString();
          } else if (
            rootState.profile?.user?.walletExists &&
            error?.message
              ?.toLowerCase()
              .includes(
                'the ethereum address of connecting wallet must match the gala wallet when connecting for the first time',
              )
          ) {
            code = WalletConnectionErrorCode.GALA_WALLET_HOLDER;
            message = this.$i18n
              .t(
                'components.wallet.connectWeb3Wallet.errorMessages.w3wAddressMustMatchGalaWallet',
              )
              .toString();
            dialogError = {
              code,
              message,
              provider: label,
            };
          }

          if (!dialogError) {
            dialogError = {
              code: WalletConnectionErrorCode.MISSING_BROWSER_CONNECTION,
              message: state.connectedExternalWallet
                ? this.$i18n
                    .t(
                      'components.home.walletConnectionErrorBanner.missingBrowserConnection',
                      {
                        provider: connectedProviderDisplayLabel,
                        lastFourOfAddress:
                          state.connectedExternalWallet.ethAddress.slice(-4),
                      },
                    )
                    .toString()
                : this.$i18n
                    .t(
                      'components.wallet.connectWeb3Wallet.installProviderDialog.bodyWithName',
                      {
                        provider: requestedProviderDisplayLabel,
                      },
                    )
                    .toString(),
              provider: connectedProviderOption?.label ?? label,
            };
          }
        }
      }
      if (dialogError) {
        commit('setWalletConnectionError', dialogError);
      }
      return {
        success: false,
        message,
        cause: code,
      };
    }
  },

  async connectWalletToUser(
    { commit, state, dispatch },
    { twoFaWrapper }: { twoFaWrapper: any },
  ) {
    try {
      const { address, label } = state.connectedWallet;
      const signer = state.web3Provider!.getSigner(address);
      const timestamp = Date.now().toString();
      const signedContent = `Welcome to Gala Games. ${timestamp}`;
      const sig = await signer.signMessage(signedContent);
      const variables = {
        authData: { ethAddress: address, timestamp, signedContent, sig },
        walletProvider: label,
      };

      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;
        const { data } = await twoFaWrapper(async (totpToken: string) => {
          return client.mutate<
            IBaseGqlResponse<'connectWallet'>,
            IConnectWalletMutationOptions
          >({
            mutation: connectWalletMutation,
            variables: { ...variables, totpToken },
          });
        }, TwoFaCheckpoint.web3WalletConnection);

        if (data?.connectWallet) {
          const { success } = data.connectWallet;
          if (success) {
            try {
              await this.$auth.forceTokenRefresh();
            } catch (err) {
              this.$sentry.captureException(err);
            }

            dispatch('inventory/getWalletsData', null, { root: true });
            await dispatch('profile/refreshUser', null, { root: true });
          }
          return data.connectWallet;
        }
      }
    } catch (error) {
      this.$sentry.captureException(error);
      console.log(error);
      return {
        success: false,
        message: this.$i18n.t(
          isErrorWithCode(error) && error.code === 'ACTION_REJECTED'
            ? 'components.wallet.connectWeb3Wallet.errorMessages.connectionRejected'
            : 'components.wallet.connectWeb3Wallet.errorMessages.unableToConnectToUser',
        ) as string,
      };
    }
  },

  async reestablishW3wConnection({ commit, dispatch, state }) {
    const { ethAddress: externalWalletAddress, walletProvider: label } =
      state.connectedExternalWallet;
    if (externalWalletAddress && !state.web3Provider) {
      try {
        const connectionToAppResponse = await dispatch('connectWalletToApp', {
          label,
          connectToUser: false,
        });

        if (!connectionToAppResponse.success) {
          throw new Error(connectionToAppResponse.message);
        }
      } catch (error) {
        this.$sentry.captureException(error);
        console.error(error);
      }
    }
  },

  async disconnectWalletFromUser(
    { commit, state, dispatch },
    {
      twoFaWrapper,
    }: {
      twoFaWrapper: <TReturnType>(
        queryDelegate: (twoFaPin: string) => Promise<TReturnType>,
        twoFaCheckpoint?: TwoFaCheckpoint,
      ) => Promise<TReturnType>;
    },
  ) {
    try {
      const { address, label } = state.connectedWallet;
      const signer = state.web3Provider!.getSigner(address);
      const timestamp = Date.now().toString();
      const signedContent = `Disconnecting wallet from user. ${timestamp}`;
      const sig = await signer.signMessage(signedContent);
      const variables = {
        authData: { ethAddress: address, timestamp, signedContent, sig },
        walletProvider: label,
      };

      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;
        const { data } = await twoFaWrapper(async (totpToken: string) => {
          return client.mutate<
            IBaseGqlResponse<'disconnectWallet'>,
            IConnectWalletMutationOptions
          >({
            mutation: disconnectWalletMutation,
            variables: { ...variables, totpToken },
          });
        }, TwoFaCheckpoint.web3WalletConnection);

        if (data?.disconnectWallet) {
          const { success } = data.disconnectWallet;
          if (success) {
            try {
              await this.$auth.forceTokenRefresh();
            } catch (err) {
              this.$sentry.captureException(err);
            }

            dispatch('inventory/getWalletsData', null, { root: true });
            await dispatch('profile/refreshUser', null, { root: true });
            commit('resetWeb3Provider');
            commit('resetConnectedWallet');
          }
          return data.disconnectWallet;
        }
      }
    } catch (err) {
      this.$sentry.captureException(err);
      const error = err as IProviderRpcError;
      let message = 'Unable to disconnect wallet from user account';
      if (error?.code === 4001) {
        message = 'Action rejected by user in web3 provider';
      }
      return {
        success: false,
        message,
      };
    }
  },

  verifyNetwork({ commit, state }, networkId: Network): boolean {
    const isValidNetwork = networkId && networkId === state.expectedNetwork;
    if (!isValidNetwork) {
      const networkName = state.networks[networkId];
      const expectedNetworkName = state.networks[state.expectedNetwork];
      const messageKey = networkName
        ? 'components.wallet.connectWeb3Wallet.errorMessages.invalidKnownNetwork'
        : 'components.wallet.connectWeb3Wallet.errorMessages.invalidUnknownNetwork';

      const errorMessage = this.$i18n
        .t(messageKey, {
          network: networkName,
          expectedNetworkName,
        })
        .toString();

      throw new WalletConnectionError(
        errorMessage,
        WalletConnectionErrorCode.INVALID_NETWORK,
      );
    }

    return true;
  },

  async handleHighRiskWalletError({ commit, dispatch }, showSnackbar: boolean) {
    // If we make it here, the address is risky and has been disconnected from the user on the backend
    // that means we need to refresh auth token and get up-to-date data about the user's wallet
    try {
      await this.$auth.forceTokenRefresh();
    } catch (err) {
      this.$sentry.captureException(err);
    }
    dispatch('inventory/getWalletsData', null, { root: true });
    await dispatch('profile/refreshUser', null, { root: true });
    commit('resetWeb3Provider');
    commit('resetConnectedWallet');
    const message = this.$i18n.t(
      'components.wallet.connectWeb3Wallet.errorMessages.riskyWallet',
    ) as string;
    if (showSnackbar) {
      commit('updateSnackbarErrorText', message, { root: true });
      commit('toggleErrorSnackbar', null, { root: true });
    }
    return message;
  },

  async validateWalletAddressIsSafe({ commit, state, dispatch }) {
    try {
      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;
        await client.query({
          query: EthAddressRiskAssessment,
          fetchPolicy: 'cache-first',
        });
        return true;
      }
    } catch (error) {
      const e = error as Error;
      if (e.message.includes('HIGH_RISK_WALLET')) {
        await dispatch('handleHighRiskWalletError', true);
        return false;
      }
      return true;
    }
  },
};
