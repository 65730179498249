import { render, staticRenderFns } from "./PromoBox.vue?vue&type=template&id=49d849ee&scoped=true&"
import script from "./PromoBox.vue?vue&type=script&lang=ts&setup=true&"
export * from "./PromoBox.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./PromoBox.vue?vue&type=style&index=0&id=49d849ee&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d849ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PromoBackground: require('/builds/gala-games/gala-film/gala-film/src/components/PromoBox/PromoBackground.vue').default,SanityBlockContent: require('/builds/gala-games/gala-film/gala-film/src/components/Sanity/SanityBlockContent.vue').default,PromoBoxShare: require('/builds/gala-games/gala-film/gala-film/src/components/PromoBox/PromoBoxShare.vue').default})
