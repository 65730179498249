import { render, staticRenderFns } from "./PromoBoxDialog.vue?vue&type=template&id=7e4f3cc2&scoped=true&"
import script from "./PromoBoxDialog.vue?vue&type=script&lang=ts&setup=true&"
export * from "./PromoBoxDialog.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./PromoBoxDialog.vue?vue&type=style&index=0&id=7e4f3cc2&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e4f3cc2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PromoBoxExchange: require('/builds/gala-games/gala-film/gala-film/src/components/PromoBox/PromoBoxExchange.vue').default,ThemeProvider: require('/builds/gala-games/gala-film/gala-film/src/components/ThemeProvider.vue').default})
