import { IProject, IProjectTimeline } from '~/types/project';
import { IPromotion } from '~/types/promotion';
import {
  IEpisode,
  IEpisodeWatchProgress,
  IEpisodeWatchSuggestion,
} from '~/types/series';

export interface IProjectState {
  projects: Record<string, IProject>;
  projectSeriesState: Record<string, IProjectSeriesState>;
  showingVideo: boolean;
  viewingRestrictions: Record<string, boolean>;
  continueRzrEp1AfterUA?: boolean;
  nftPromotionsState: IProjectNftPromotionsState;
  projectTimeline: Record<string, IProjectTimeline>;
  currentEpisode?: IEpisode;
  nextEpisode?: IEpisode;
  projectsAutoPlayed: string[];
}

export interface IGetProjectPayload {
  projectSlug: string;
  getSeries?: boolean;
  getPosters?: boolean;
}

export interface IProjectNftPromotionsState {
  show: boolean;
  promotion?: IPromotion;
  projectSlug?: string;
  shownPromotionIds: string[];
}

export interface IProjectSeriesState {
  episodeWatchProgress?: Record<string, IEpisodeWatchProgress>;
  episodeWatchSuggestion?: IEpisodeWatchSuggestion;
}

export const RZR_PROJECT_SLUG = 'rzr';
export const RZR_EPISODE_1_SLUG = 'rzr-episode-1';
export const RZR_EPISODE_1_UA_CUTOFF_SECS = 116;
export const CHICAGO_IM_HOME_SLUG = 'chicago-im-home';
