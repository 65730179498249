import { render, staticRenderFns } from "./PrivateKeyButton.vue?vue&type=template&id=5a718f7e&"
import script from "./PrivateKeyButton.vue?vue&type=script&lang=ts&"
export * from "./PrivateKeyButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FullScreenDialog: require('/builds/gala-games/gala-film/gala-film/src/components/FullScreenDialog.vue').default})
